import Axios from "axios";

class PedidoDataService {

  getAll() {
    return Axios.get('pedidosTransporte');
  }

  getPage(params) {
    return Axios.get('pedidosTransporte/page', { params })
  }

  create(params) {
    return Axios.post('pedidosTransporte', params)
  }

  update(params) {
    return Axios.put('pedidosTransporte/' + (params.id), params)
  }

  find(id) {
    return Axios.get(`pedidosTransporte/${id}`)
  }

  delete(id) {
    return Axios.delete(`pedidosTransporte/${id}`);
  }

  getUser() {
    return Axios.get(`usuarios/dados_Usuario/simplified`)
  }

  getPermissoes() {
    return Axios.get(`usuarios/permissoes`)
  }

  getProdutos() {
    return Axios.get('produtos')
  }

  getFilter(params) {
    return Axios.post("pedidosTransporte/filter", params);
  }
}

export default new PedidoDataService()
