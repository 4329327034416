<template>
  <v-text-field
    v-model="cep"
    :label="$t('CEP')"
    v-mask="'##.###-###'"
    name="cep"
    @blur="buscaCep()"
    class="input-style font-size-input text-light-input placeholder-light input-icon"
    dense
    flat
    filled
    solo
  />
</template>

<script>
import DataService from "@/services/ComumDataService";
export default {
  name: "BuscaCep",

  props: {
    cepProp: { type: String, required: true },
  },

  mounted() {
    this.cep = this.cepProp;

    this.getCidades();
  },

  data() {
    return {
      cidades: [],
      cep: "",
    };
  },

  watch: {
    cepProp: function (val) {
      this.cep = val;
    },

    cep: function (val) {
      this.$emit("cep", val);
    },
  },

  methods: {
    buscaCep() {
      if (this.cep.length >= 8) {
        DataService.buscaCep(this.limpaMascara(this.cep)).then(
          async (response) => {
            DataService.findCidade({
              cidade: response.data.localidade,
              uf: response.data.uf,
            }).then((cidade) => {
              this.$emit("cidade", cidade.data);
              this.$emit("logradouro", response.data.logradouro);
              this.$emit("bairro", response.data.bairro);

              this.$emit("endereco", {
                cidade: cidade.data,
                logradouro: response.data.logradouro,
                bairro: response.data.bairro,
              });
            });
          }
        );
      }
    },

    getCidades() {
      DataService.getCidades()
        .then((response) => {
          this.cidades = response.data;
          this.$emit("cidades", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
</style>
