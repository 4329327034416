import Axios from "axios";

class PedidoUniformeDataService {

  getAll() {
    return Axios.get('pedidoUniforme');
  }

  getPage(params) {
    return Axios.get('pedidoUniforme/page', { params })
  }

  create(params) {
    return Axios.post('pedidoUniforme', params)
  }

  update(params) {
    return Axios.put('pedidoUniforme/' + (params.id), params)
  }

  find(id) {
    return Axios.get(`pedidoUniforme/${id}`)
  }

  delete(id) {
    return Axios.delete(`pedidoUniforme/${id}`);
  }

  getUser() {
    return Axios.get(`usuarios/dados_Usuario/simplified`)
  }

  getPermissoes() {
    return Axios.get(`usuarios/permissoes`)
  }

  getProdutos() {
    return Axios.get('produtos/ativos/tipoProduto/3')
  }

  getProdutosEstoque() {
    return Axios.get('produtos/estoque/tipoProduto/3')
  }

  getPrintSingle(id, params) {
    return Axios.get(`pedidoUniforme/romaneio/${id}`, { params })
  }

  get(url, params = null) {
    return Axios.get(url, params)
  }

  separacao(params) {
    return Axios.put('pedidoUniforme/separacao/' + (params.id), params)
  }

  disponibilizado(params) {
    return Axios.put('pedidoUniforme/disponibilizado/' + (params.id), params)
  }

  // retiradoTerceiro(params) {
  //   return Axios.put('pedidoUniforme/retiradoTerceiro/' + (params.id), params)
  // }

  retirado(params) {
    return Axios.put('pedidoUniforme/retirado/' + (params.id), params)
  }

  recebido(params) {
    return Axios.put('pedidoUniforme/recebido/' + (params.id), params)
  }

  entregaPendente(params) {
    return Axios.put('pedidoUniforme/entregaPendente/' + (params.id), params)
  }

  entregaTotal(params) {
    return Axios.put('pedidoUniforme/entregaTotal/' + (params.id), params)
  }

  entregaParcial(params) {
    return Axios.put('pedidoUniforme/entregaParcial/' + (params.id), params)
  }

  trocar(params) {
    return Axios.put('pedidoUniforme/troca/' + (params.id), params)
  }

  getFilter(params) {
    return Axios.post("pedidoUniforme/filter", params);
  }
  export(params) {
    return Axios.put("pedidoUniforme/export", params);
  }
}

export default new PedidoUniformeDataService()
