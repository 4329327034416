<template>
  <div>
    <v-dialog
      v-model="modalHistoricoBeneficios"
      max-width="1200"
      class="dialog-deletado"
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="close"
            text
            @click="modalHistoricoBeneficios = false"
            height="39"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
          >
            {{ $t("Fechar") }}
          </v-btn>
        </v-card-actions>
        <v-card-title class="headline"
          >{{ $t("Beneficio_apuracao") }}
        </v-card-title>

        <v-data-table
          :headers="headerHistoricoBeneficios"
          :items="listagemHistoricoBeneficios"
          class="elevation-1"
          disable-pagination
        >
          <template v-slot:[`item.data`]="{ item }">
            <span>{{ item.data | dataParaLeitura }}</span>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <span> {{ $t(item.status) }}</span>
          </template>

          <template v-slot:[`item.vaTotal`]="{ item }">
            <span v-if="item.vaTotal >= 0">
              {{ item.vaTotal | formatoMonetario }}</span
            >
            <span v-else style="color: red">{{
              item.vaTotal | formatoMonetario
            }}</span>
          </template>

          <template v-slot:[`item.vtTotal`]="{ item }">
            <span v-if="item.vtTotal >= 0">
              {{ item.vtTotal | formatoMonetario }}</span
            >
            <span v-else style="color: red">{{
              item.vtTotal | formatoMonetario
            }}</span>
          </template>

          <template v-slot:[`item.vrTotal`]="{ item }">
            <span v-if="item.vrTotal >= 0">
              {{ item.vrTotal | formatoMonetario }}</span
            >
            <span v-else style="color: red">{{
              item.vrTotal | formatoMonetario
            }}</span>
          </template>

          <template v-slot:[`item.outrosTotal`]="{ item }">
            <span v-if="item.outrosTotal >= 0">
              {{ item.outrosTotal | formatoMonetario }}</span
            >
            <span v-else style="color: red">{{
              item.outrosTotal | formatoMonetario
            }}</span>
          </template>

          <template v-slot:[`item.valorTotal`]="{ item }">
            <span v-if="item.valorTotal >= 0">
              {{ item.valorTotal | formatoMonetario }}</span
            >
            <span v-else style="color: red">{{
              item.valorTotal | formatoMonetario
            }}</span>
          </template>

          <template
            v-slot:[`item.beneficioPeriodoApuracao.inicioPeriodo`]="{ item }"
          >
            {{ item.beneficioPeriodoApuracao.inicioPeriodo | dataParaLeitura }}
          </template>

          <template
            v-slot:[`item.beneficioPeriodoApuracao.finalPeriodo`]="{ item }"
          >
            {{ item.beneficioPeriodoApuracao.finalPeriodo | dataParaLeitura }}
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modalBeneficiosOptante"
      max-width="1200"
      class="dialog-deletado"
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="close"
            text
            @click="modalBeneficiosOptante = false"
            height="39"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
          >
            {{ $t("Fechar") }}
          </v-btn>
        </v-card-actions>
        <v-card-title class="headline"
          >{{ $t("Listagem_de_beneficioconvenios") }}
        </v-card-title>

        <v-data-table
          :headers="headerBeneficiosOptante"
          :items="listagemBeneficiosOptante"
          class="elevation-1"
          disable-pagination
        >
          <template v-slot:[`item.data`]="{ item }">
            <span>{{ item.data | dataParaLeitura }}</span>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <span> {{ $t(item.status) }}</span>
          </template>

          <template v-slot:[`item.beneficioConvenio.valorUnitario`]="{ item }">
            <span v-if="item.beneficioConvenio.valorUnitario >= 0">
              {{
                item.beneficioConvenio.valorUnitario | formatoMonetario
              }}</span
            >
            <span v-else style="color: red">{{
              item.beneficioConvenio.valorUnitario | formatoMonetario
            }}</span>
          </template>
          <template v-slot:[`item.inicio`]="{ item }">
            <span>{{ item.inicio | dataHoraParaLeitura }}</span>
          </template>
          <template v-slot:[`item.encerramento`]="{ item }">
            <span>{{ item.encerramento | dataHoraParaLeitura }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modalDocumentacaoAcessoria"
      max-width="1200"
      class="dialog-deletado"
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="close"
            text
            @click="modalDocumentacaoAcessoria = false"
            height="39"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            :loading="loading"
          >
            {{ $t("Fechar") }}
          </v-btn>
        </v-card-actions>
        <v-card-title class="headline"
          >{{ $t("documentacaoacessoria") }}
        </v-card-title>

        <v-data-table
          :headers="headerDocumentacaoAcessoria"
          :items="listagemDocumentacaoAcessoria"
          class="elevation-1"
          disable-pagination
        >
          <template v-slot:[`item.dtInclusao`]="{ item }">
            <span>{{ item.dtInclusao | dataParaLeitura }}</span>
          </template>
          <template v-slot:[`item.assinado`]="{ item }">
            <span>{{ item.assinado ? $t("sim") : $t("nao") }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              name="printBtn"
              class="mr-2"
              :label="$t('Imprimir')"
              :title="$t('Imprimir')"
              @click="printSingle(item)"
              color="#67748e"
              >ni-collection</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalGed" max-width="1200" class="dialog-deletado">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="close-ged"
            text
            @click="modalNewDocGed = true"
            height="39"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            v-can="'ROLE_FuncionarioGed_Insert'"
            :loading="loading"
          >
            {{ $t("novo_registro") }}
          </v-btn>
          <v-btn
            id="close-ged"
            text
            @click="modalGed = false"
            height="39"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            :loading="loading"
          >
            {{ $t("Fechar") }}
          </v-btn>
        </v-card-actions>
        <v-card-title class="headline">{{ $t("GED") }} </v-card-title>

        <v-data-table
          :headers="headerGed"
          :items="listagemGed"
          class="elevation-1"
          disable-pagination
        >
          <template v-slot:[`item.dtInclusao`]="{ item }">
            <span>{{ item.dtInclusao | dataParaLeitura }}</span>
          </template>
          <template v-slot:[`item.assinado`]="{ item }">
            <span>{{ item.assinado ? $t("sim") : $t("nao") }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              name="printBtn"
              class="mr-2"
              :label="$t('Imprimir')"
              :title="$t('Imprimir')"
              @click="downloadAnexo(item.url)"
              color="#67748e"
              >ni-collection</v-icon
            >
            <v-icon
              small
              name="deleteBtn"
              class="mr-2"
              :label="$t('Excluir')"
              :title="$t('Excluir')"
              @click="deleteAnexo(item)"
              color="#67748e"
            >
              ni-fat-remove</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalNewDocGed" max-width="600" class="dialog-deletado">
      <v-card>
        <v-card-title> Dados de armazenamento físico </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="descricao" label="Descrição documento" />

            <v-file-input @change="changeFile" label="Arquivo" accept=".pdf" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            @click="newDocGedFunction()"
            :loading="loading"
            >{{ $t("Enviar") }}</v-btn
          >
          <v-btn
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            @click="closeModalNewGed()"
            :loading="loading"
          >
            {{ $t("Fechar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <EditarBase
      nomeSingular="Funcionario"
      nomePlural="Funcionarios"
      @recurso="recurso = $event"
      @submitSuccess="emitSubmitSuccess"
      @submitError="emitSubmitError"
      @closeModalEdit="closeModalEdit"
    >
      <v-col cols="12">
        <v-row>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>{{
                this.$t("Dados_pessoais")
              }}</v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    v-can="'ROLE_Funcionario_UpdatePerson'"
                  >
                    <TabelaSelecionaRecurso
                      @recurso="recurso.pessoa = $event"
                      :payload="recurso.pessoa"
                      :editar="false"
                      url="pessoas"
                      titulo="Pessoa"
                      :headers="[
                        {
                          text: this.$t('Nome'),
                          value: 'nome',
                          sortable: 'false',
                        },
                        {
                          text: this.$t('CPF'),
                          value: 'cpf',
                          sortable: 'false',
                        },
                        {
                          text: this.$t('Email'),
                          value: 'email',
                          sortable: 'false',
                        },
                        {
                          text: this.$t('Acoes'),
                          value: 'actions',
                          sortable: false,
                        },
                      ]"
                    />
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Data_de_nascimento") }}
                    </v-span>
                    <CampoData
                      @date="recurso.dtNascimento = $event"
                      :label="$t('Data_de_nascimento')"
                      :payload="recurso.dtNascimento"
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Instrucao") }}
                    </v-span>
                    <v-select
                      :items="[
                        { text: $t('Analfabeto'), value: 'ANALFABETO' },
                        {
                          text: $t('Quinto_ano_incompleto'),
                          value: 'QUINTOANOINCOMPLETO',
                        },
                        {
                          text: $t('Quinto_ano_completo'),
                          value: 'QUINTOANOCOMPLETO',
                        },
                        {
                          text: $t('Fundamental_incompleto'),
                          value: 'FUNDAMENTALINCOMPLETO',
                        },
                        {
                          text: $t('Fundamental_completo'),
                          value: 'FUNDAMENTALCOMPLETO',
                        },
                        {
                          text: $t('Ensino_medio_incompleto'),
                          value: 'ENSMEDIOINCOMPLETO',
                        },
                        {
                          text: $t('Ensino_medio_completo'),
                          value: 'ENSMEDIOCOMPLETO',
                        },
                        {
                          text: $t('Ensino_superior_incompleto'),
                          value: 'SUPERIORINCOMPLETO',
                        },
                        {
                          text: $t('Ensino_superior_completo'),
                          value: 'SUPERIORCOMPLETO',
                        },
                        {
                          text: $t('Pos_graduado'),
                          value: 'POSGRADUADO',
                        },
                        { text: $t('Mestrado'), value: 'MESTRADO' },
                        { text: $t('Doutorado'), value: 'DOUTORADO' },
                      ]"
                      :label="$t('Instrucao')"
                      v-model="recurso.instrucao"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Raca") }}
                    </v-span>
                    <v-select
                      :items="[
                        { text: $t('Branca'), value: 'BRANCA' },
                        { text: $t('Preta'), value: 'PRETA' },
                        { text: $t('Parda'), value: 'PARDA' },
                        { text: $t('Amarela'), value: 'AMARELA' },
                        { text: $t('Indigena'), value: 'INDIGENA' },
                      ]"
                      :label="$t('Raca')"
                      v-model="recurso.raca"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Sexo") }}
                    </v-span>
                    <v-select
                      :items="[
                        { text: $t('Masculino'), value: 'MASCULINO' },
                        { text: $t('Feminino'), value: 'FEMININO' },
                      ]"
                      :label="$t('Sexo')"
                      v-model="recurso.pessoa.sexo"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Naturalidade") }}
                    </v-span>
                    <v-autocomplete
                      :items="this.cidades"
                      :item-text="
                        (item) =>
                          item.nome +
                          '/' +
                          item.estado.sigla +
                          ' - ' +
                          item.estado.pais.nome
                      "
                      return-object
                      :label="$t('Naturalidade')"
                      v-model="recurso.naturalidade"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Estado_civil") }}
                    </v-span>
                    <v-autocomplete
                      :items="[
                        { text: $t('Solteiro'), value: 'SOLTEIRO' },
                        { text: $t('Casado'), value: 'CASADO' },
                        { text: $t('Divorciado'), value: 'DIVORCIADO' },
                        { text: $t('Separado'), value: 'SEPARADO' },
                        { text: $t('Viuvo'), value: 'VIUVO' },

                        { text: $t('Comcubinado'), value: 'COMCUBINADO' },
                        { text: $t('UniaoEstavel'), value: 'UNIAOESTAVEL' },
                      ]"
                      item-text="text"
                      item-value="value"
                      :label="$t('Estado_civil')"
                      v-model="recurso.estadoCivil"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Grupo_sanguineo") }}
                    </v-span>
                    <v-select
                      :items="[
                        { text: $t('APOSITIVO'), value: 'APOSITIVO' },
                        { text: $t('ANEGATIVO'), value: 'ANEGATIVO' },
                        { text: $t('BPOSITIVO'), value: 'BPOSITIVO' },
                        { text: $t('BNEGATIVO'), value: 'BNEGATIVO' },
                        { text: $t('ABPOSITIVO'), value: 'ABPOSITIVO' },
                        { text: $t('ABNEGATIVO'), value: 'ABNEGATIVO' },
                        { text: $t('OPOSITIVO'), value: 'OPOSITIVO' },
                        { text: $t('ONEGATIVO'), value: 'ONEGATIVO' },
                      ]"
                      :label="$t('Grupo_sanguineo')"
                      v-model="recurso.grupoSanguineo"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Nome_da_mae") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.nomeMae"
                      :label="$t('Nome_da_mae')"
                      name="nomeMae"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Nome_do_pai") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.nomePai"
                      :label="$t('Nome_do_pai')"
                      name="nomePai"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Nome_do_conjuge") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.nomeConjuge"
                      :label="$t('Nome_do_conjuge')"
                      name="nomeConjuge"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Nome_social") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.nomeSocial"
                      :label="$t('Nome_social')"
                      name="nomeSocial"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>{{
                this.$t("Informacoes_da_contratacao")
              }}</v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Matricula") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.matricula"
                      :label="$t('Matricula')"
                      name="matricula"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" id="admissao">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Data_de_admissao") }}
                    </v-span>
                    <CampoData
                      :payload="recurso.admissao"
                      @date="recurso.admissao = $event"
                      :label="$t('Data_de_admissao')"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Status") }}
                    </v-span>
                    <v-select
                      :items="[
                        { text: $t('Ativo'), value: 'ATIVO' },
                        { text: $t('Ferias'), value: 'FERIAS' },
                        { text: $t('Afastado'), value: 'AFASTADO' },
                        { text: $t('Desligado'), value: 'DESLIGADO' },
                      ]"
                      :label="$t('Status')"
                      v-model="recurso.status"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" v-if="recurso.status === 'DESLIGADO'">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Data_de_desligamento") }}
                    </v-span>
                    <CampoData
                      @date="recurso.dtDesligamento = $event"
                      :label="$t('Data_de_desligamento')"
                      :payload="recurso.dtDesligamento"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Data_termino_do_contrato") }}
                    </v-span>
                    <CampoData
                      @date="recurso.terminoContrato = $event"
                      :label="$t('Data_termino_do_contrato')"
                      :payload="recurso.terminoContrato"
                    />
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Tipo_de_contrato") }}
                    </v-span>
                    <v-select
                      :items="[
                        {
                          text: $t('Prazo_indeterminado'),
                          value: 'PRAZOINDETERMINADO',
                        },
                        {
                          text: $t('PRAZODETERMEMDIAS'),
                          value: 'PRAZODETERMEMDIAS',
                        },
                        {
                          text: $t('PRAZODETERMVINCAOCORRENCIA'),
                          value: 'PRAZODETERMVINCAOCORRENCIA',
                        },
                        {
                          text: $t('INTERMITENTE'),
                          value: 'INTERMITENTE',
                        },
                      ]"
                      :label="$t('Tipo_de_contrato')"
                      v-model="recurso.contratoTipo"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-checkbox v-model="recurso.primeiroEmprego">
                      <template v-slot:label>
                        <div>{{ $t("Primeiro_emprego") }}</div>
                      </template>
                    </v-checkbox>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field-money
                      v-model="recurso.salarioBase"
                      v-bind:label="$t('Salario_base')"
                      v-bind:properties="{
                        prefix: 'R$',
                        readonly: false,
                        disabled: false,
                        outlined: false,
                        clearable: true,
                        rules: required,
                      }"
                      v-bind:options="{
                        locale: 'pt-BR',
                        length: 12,
                        precision: 2,
                        empty: '0',
                      }"
                      class="font-size-input text-light-input placeholder-light"
                      dense
                      flat
                      filled
                      solo
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Unidade_de_salario") }}
                    </v-span>
                    <v-select
                      :items="[
                        { text: $t('Por_hora'), value: 'PORHORA' },
                        { text: $t('Por_dia'), value: 'PORDIA' },
                        { text: $t('Por_semana'), value: 'PORSEMANA' },
                        { text: $t('Por_quinzena'), value: 'PORQUINZENA' },
                        { text: $t('Por_mes'), value: 'PORMES' },
                        { text: $t('Por_tarefa'), value: 'PORTAREFA' },
                        { text: $t('Variavel'), value: 'VARIAVEL' },
                      ]"
                      :label="$t('Unidade_de_salario')"
                      v-model="recurso.salarioUnidade"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="5">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Sindicato") }}
                    </v-span>
                    <v-autocomplete
                      disabled
                      :items="this.itensSindicato"
                      item-text="descricao"
                      return-object
                      :label="$t('Sindicato')"
                      v-model="recurso.sindicato"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    >
                    </v-autocomplete>
                  </v-col>

                  <!-- <v-col cols="12" md="6">
                  <v-select
                    :items="[
                      { text: $t('Ativo'), value: 0 },
                      { text: $t('Ferias'), value: 1 },
                      { text: $t('Afastado'), value: 2 },
                      { text: $t('Desligado'), value: 3 },
                    ]"
                    :label="$t('Status')"
                    v-model="recurso.status"
                  ></v-select>
                </v-col> -->

                  <!-- <v-col cols="12" md="6">
                  <v-select
                    :items="[
                      { text: $t('Ativo'), value: 'ATIVO' },
                      { text: $t('Ferias'), value: 'FERIAS' },
                      { text: $t('Afastado'), value: 'AFASTADO' },
                      { text: $t('Desligado'), value: 'DESLIGADO' },
                    ]"
                    :label="$t('Status')"
                    v-model="recurso.status"
                  ></v-select>
                </v-col> -->

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Tipo_de_jornada") }}
                    </v-span>
                    <v-select
                      :items="[
                        { text: $t('SUBHRTRAB'), value: 'SUBHRTRAB' },
                        {
                          text: $t('ATIVIDADEEXTRA'),
                          value: 'ATIVIDADEEXTRA',
                        },
                        {
                          text: $t('FUNCOESESPEC'),
                          value: 'FUNCOESESPEC',
                        },
                        {
                          text: $t('TELETRABALHO'),
                          value: 'TELETRABALHO',
                        },
                      ]"
                      :label="$t('Tipo_de_jornada')"
                      v-model="recurso.tipoJornada"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Data_de_termino_experiencia") + " 1" }}
                    </v-span>
                    <CampoData
                      @date="recurso.experienciaTermino1 = $event"
                      :label="$t('Data_de_termino_experiencia') + ' 1'"
                      :payload="recurso.experienciaTermino1"
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Data_de_termino_experiencia") + " 2" }}
                    </v-span>
                    <CampoData
                      @date="recurso.experienciaTermino2 = $event"
                      :label="$t('Data_de_termino_experiencia') + ' 2'"
                      :payload="recurso.experienciaTermino2"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    v-if="
                      possuiParametrizacaoInquilino(
                        'INFORMACAORESUMIDAVALETRANSPORTECONTRATACAOFUNCIONARIO'
                      )
                    "
                  >
                    <v-checkbox v-model="recurso.optanteValeTransporte">
                      <template v-slot:label>
                        <p>{{ $t("OptanteValeTransporte") }}</p>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12" md="3" v-if="recurso.optanteValeTransporte">
                    <v-text-field-money
                      v-model="recurso.valorDiarioValeTransporte"
                      v-bind:label="$t('ValorDiarioValeTransporte')"
                      v-bind:properties="{
                        prefix: 'R$',
                        readonly: false,
                        disabled: false,
                        outlined: false,
                        clearable: true,
                        rules: required,
                      }"
                      v-bind:options="{
                        locale: 'pt-BR',
                        length: 12,
                        precision: 2,
                        empty: '0',
                      }"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>{{
                this.$t("Documentos")
              }}</v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Numero_ctps") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.ctpsNumero"
                      :label="$t('Numero_ctps')"
                      name="ctpsNumero"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Serie_ctps") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.ctpsSerie"
                      :label="$t('Serie_ctps')"
                      name="ctpsSerie"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-checkbox v-model="recurso.fgtsOptante">
                      <template v-slot:label>
                        <div>{{ $t("Fgts_optante") }}</div>
                      </template>
                    </v-checkbox>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-checkbox v-model="recurso.inssOptante">
                      <template v-slot:label>
                        <div>{{ $t("Inss_optante") }}</div>
                      </template>
                    </v-checkbox>
                  </v-col>

                  <!-- <v-col cols="12" md="4">
                            <v-checkbox v-model="recurso.opcaoFGTS">
                              <template v-slot:label>
                                <div>{{ $t("Opcao_fgts") }}</div>
                              </template>
                            </v-checkbox>
                          </v-col> -->

                  <v-divider></v-divider>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Categoria_reservista") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.reservistaCategoria"
                      :label="$t('Categoria_reservista')"
                      name="reservistaCategoria"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Numero_reservista") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.reservistaNumero"
                      :label="$t('Numero_reservista')"
                      name="reservistaNumero"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("CPF") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.pessoa.cpf"
                      :label="$t('CPF')"
                      name="cpf"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("PIS") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.pis"
                      :label="$t('PIS')"
                      name="pis"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("RG") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.rg"
                      :label="$t('RG')"
                      name="rg"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Expedicao_rg") }}
                    </v-span>
                    <CampoData
                      @date="recurso.rgExpedicao = $event"
                      :label="$t('Expedicao_rg')"
                      :payload="recurso.rgExpedicao"
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Uf_expedicao_rg") }}
                    </v-span>
                    <v-autocomplete
                      :items="this.estados"
                      item-text="nome"
                      return-object
                      :label="$t('Uf_expedicao_rg')"
                      v-model="recurso.rgUF"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("CNH") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.cnh"
                      :label="$t('CNH')"
                      name="cnh"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Categoria_cnh") }}
                    </v-span>
                    <v-select
                      :items="[
                        { text: 'A', value: 1 },
                        { text: 'B', value: 2 },
                        { text: 'C', value: 3 },
                        { text: 'D', value: 4 },
                        { text: 'E', value: 5 },
                        { text: 'AB', value: 6 },
                        { text: 'AC', value: 7 },
                        { text: 'AD', value: 8 },
                        { text: 'AE', value: 9 },
                      ]"
                      :label="$t('Categoria_cnh')"
                      v-model="recurso.cnhCategoria"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Uf_cnh") }}
                    </v-span>
                    <v-autocomplete
                      :items="this.estados"
                      item-text="nome"
                      return-object
                      :label="$t('Uf_cnh')"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Cnh_validade") }}
                    </v-span>
                    <CampoData
                      @date="recurso.cnhValidade = $event"
                      :label="$t('Cnh_validade')"
                      :payload="recurso.cnhValidade"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Cnh_emissao") }}
                    </v-span>
                    <CampoData
                      @date="recurso.cnhEmissao = $event"
                      :label="$t('Cnh_emissao')"
                      :payload="recurso.cnhEmissao"
                    />
                  </v-col>

                  <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      v-model="recurso.tituloEleitoral"
                      :label="$t('tituloEleitoral')"
                      :payload="recurso.tituloEleitoral"
                    />
                  </v-col>

                  <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      v-model="recurso.tituloEleitoralZona"
                      :label="$t('tituloEleitoralZona')"
                      :payload="recurso.tituloEleitoralZona"
                    />
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      v-model="recurso.tituloEleitoralSecao"
                      :label="$t('tituloEleitoralSecao')"
                      :payload="recurso.tituloEleitoralSecao"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>{{
                this.$t("Endereco")
              }}</v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Cidade") }}
                    </v-span>
                    <v-autocomplete
                      :label="$t('Cidade')"
                      outlined
                      v-model="recurso.pessoa.cidade"
                      :items="cidades"
                      :item-text="(item) => item.nome + '/' + item.estado.sigla"
                      return-object
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("CEP") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.pessoa.cep"
                      :label="$t('CEP')"
                      v-mask="'##.###-###'"
                      @blur="buscaCep($event)"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Descricao") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.pessoa.descricao"
                      :label="$t('Descricao')"
                      name="descricao"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Logradouro") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.pessoa.logradouro"
                      :label="$t('Logradouro')"
                      name="logradouro"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Numero") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.pessoa.numero"
                      :label="$t('Numero')"
                      name="numero"
                      type="number"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Complemento") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.pessoa.complementoEndereco"
                      :label="$t('Complemento')"
                      name="complemento"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    />
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Bairro") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.pessoa.bairro"
                      :label="$t('Bairro')"
                      name="bairro"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>{{
                this.$t("Dados_bancarios")
              }}</v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Agencia_numero") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.contaAgencia"
                      :label="$t('Agencia_numero')"
                      name="agenciaNumero"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Banco") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.contaBanco"
                      :label="$t('Banco')"
                      name="contaBanco"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Conta_numero") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.contaNumero"
                      :label="$t('Conta_numero')"
                      name="contaNumero"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-span class="opacity-5 text-dark">
                      {{ $t("Conta_operacao") }}
                    </v-span>
                    <v-text-field
                      v-model="recurso.contaOperacao"
                      :label="$t('Conta_operacao')"
                      name="contaOperacao"
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>{{
                this.$t("Deficiencias")
              }}</v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-checkbox v-model="recurso.cotaDeficiencia">
                      <template v-slot:label>
                        <div>{{ $t("Cota_deficiencia") }}</div>
                      </template>
                    </v-checkbox>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-checkbox v-model="recurso.defFisica">
                      <template v-slot:label>
                        <div>{{ $t("Deficiencia_fisica") }}</div>
                      </template>
                    </v-checkbox>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-checkbox v-model="recurso.defIntelectual">
                      <template v-slot:label>
                        <div>{{ $t("Deficiencia_intelectual") }}</div>
                      </template>
                    </v-checkbox>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-checkbox v-model="recurso.defMental">
                      <template v-slot:label>
                        <div>{{ $t("Deficiencia_mental") }}</div>
                      </template>
                    </v-checkbox>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-checkbox v-model="recurso.defAuditiva">
                      <template v-slot:label>
                        <div>{{ $t("Deficiencia_auditiva") }}</div>
                      </template>
                    </v-checkbox>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-checkbox v-model="recurso.defVisual">
                      <template v-slot:label>
                        <div>{{ $t("Deficiencia_visual") }}</div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>{{
                this.$t("Dependentes")
              }}</v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-col cols="12">
                  <CriaSuperSet
                    :podeEditarRecursoFinal="true"
                    :podeExcluirRecursoFinal="true"
                    @recursos="recurso.dependentes = $event"
                    :usaRecursoBase="false"
                    :payload="recurso.dependentes"
                    :pesquisa="false"
                    textoBotaoCadastro="Novo_registro"
                    tituloFinais="Dependentes"
                    subtituloFinais="Clique_para_adicionar"
                    :headersFinais="[
                      {
                        text: this.$t('Nome'),
                        value: 'nome',
                        sortable: 'true',
                      },
                      {
                        text: this.$t('Nascimento'),
                        value: 'nascimento',
                        sortable: 'true',
                      },

                      {
                        text: this.$t('Tipo'),
                        value: 'tipo',
                        sortable: 'true',
                      },
                      {
                        text: this.$t('Cpf'),
                        value: 'cpf',
                        sortable: 'true',
                      },

                      {
                        text: this.$t('Acoes'),
                        value: 'actions',
                        sortable: 'false',
                      },
                    ]"
                  >
                    <template v-slot:default="slotProps">
                      <v-col cols="12" md="12">
                        <v-text-field
                          :rules="rule_required"
                          v-model="slotProps.recursoFinalAtual.nome"
                          :label="$t('Nome')"
                          name="nome"
                          type="text"
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="8" md="8">
                        <v-select
                          v-model="slotProps.recursoFinalAtual.tipo"
                          :items="itensTipoDependente"
                          :label="$t('Tipo')"
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="4" md="4">
                        <CampoData
                          @date="
                            slotProps.recursoFinalAtual.nascimento = $event
                          "
                          :label="$t('Data_de_nascimento')"
                        />
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-select
                          :items="[
                            { text: $t('Masculino'), value: 'MASCULINO' },
                            { text: $t('Feminino'), value: 'FEMININO' },
                          ]"
                          :label="$t('Sexo')"
                          v-model="slotProps.recursoFinalAtual.sexo"
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-select>
                      </v-col>
                      <v-col cols="4" md="4">
                        <v-text-field
                          :rules="rule_required"
                          v-model="slotProps.recursoFinalAtual.cpf"
                          :label="$t('CPF')"
                          name="cpf"
                          type="text"
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                          v-mask="'###.###.###-##'"
                          mask-placeholder="_"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-checkbox
                          id="dependenteIrrf"
                          v-model="slotProps.recursoFinalAtual.dependenteIrrf"
                        >
                          <template v-slot:label>
                            {{ $t("dependenteIrrf") }}
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-checkbox
                          id="dependenteSalarioFamilia"
                          v-model="
                            slotProps.recursoFinalAtual.dependenteSalarioFamilia
                          "
                        >
                          <template v-slot:label>
                            {{ $t("dependenteSalarioFamilia") }}
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-checkbox
                          id="possuiIncapacidadeFisica"
                          v-model="
                            slotProps.recursoFinalAtual.possuiIncapacidadeFisica
                          "
                        >
                          <template v-slot:label>
                            {{ $t("possuiIncapacidadeFisica") }}
                          </template>
                        </v-checkbox>
                      </v-col>
                    </template>
                    <template v-slot:formEditaRecursoFinal="slotProps">
                      <v-col cols="12" md="12">
                        <v-span class="opacity-5 text-dark">
                          {{ $t("Nome") }}
                        </v-span>
                        <v-text-field
                          :rules="rule_required"
                          v-model="slotProps.recursoFinalAtual.nome"
                          :label="$t('Nome')"
                          name="nome"
                          type="text"
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="8" md="8">
                        <v-span class="opacity-5 text-dark">
                          {{ $t("Tipo") }}
                        </v-span>
                        <v-select
                          v-model="slotProps.recursoFinalAtual.tipo"
                          :items="itensTipoDependente"
                          :label="$t('Tipo')"
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="4" md="4">
                        <v-span class="opacity-5 text-dark">
                          {{ $t("Data_de_nascimento") }}
                        </v-span>
                        <CampoData
                          @date="
                            slotProps.recursoFinalAtual.nascimento = $event
                          "
                          :payload="slotProps.recursoFinalAtual.nascimento"
                          :label="$t('Data_de_nascimento')"
                        />
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-span class="opacity-5 text-dark">
                          {{ $t("Sexo") }}
                        </v-span>
                        <v-select
                          :items="[
                            { text: $t('Masculino'), value: 'MASCULINO' },
                            { text: $t('Feminino'), value: 'FEMININO' },
                          ]"
                          :label="$t('Sexo')"
                          v-model="slotProps.recursoFinalAtual.sexo"
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-select>
                      </v-col>
                      <v-col cols="4" md="4">
                        <v-span class="opacity-5 text-dark">
                          {{ $t("CPF") }}
                        </v-span>
                        <v-text-field
                          :rules="rule_required"
                          v-model="slotProps.recursoFinalAtual.cpf"
                          :label="$t('CPF')"
                          name="cpf"
                          type="text"
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                          v-mask="'###.###.###-##'"
                          mask-placeholder="_"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-checkbox
                          id="dependenteIrrf"
                          v-model="slotProps.recursoFinalAtual.dependenteIrrf"
                        >
                          <template v-slot:label>
                            {{ $t("dependenteIrrf") }}
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-checkbox
                          id="dependenteSalarioFamilia"
                          v-model="
                            slotProps.recursoFinalAtual.dependenteSalarioFamilia
                          "
                        >
                          <template v-slot:label>
                            {{ $t("dependenteSalarioFamilia") }}
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-checkbox
                          id="possuiIncapacidadeFisica"
                          v-model="
                            slotProps.recursoFinalAtual.possuiIncapacidadeFisica
                          "
                        >
                          <template v-slot:label>
                            {{ $t("possuiIncapacidadeFisica") }}
                          </template>
                        </v-checkbox>
                      </v-col>
                    </template>
                  </CriaSuperSet>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>{{
                this.$t("Informacoes_adicionais")
              }}</v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-btn
                    :loading="loading"
                    @click="showBeneficiosOptante(recurso)"
                    v-can="'ROLE_BeneficioConvenioOptante_List'"
                    class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                    height="39"
                  >
                    <v-icon left dark> mdi-shopping-search-outline </v-icon>
                    {{ $t("Listagem_de_beneficioconvenios") }}
                  </v-btn>

                  <v-btn
                    :loading="loading"
                    @click="showHistoricoBeneficios(recurso)"
                    v-can="'ROLE_BeneficioApuracao_List'"
                    class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                    height="39"
                  >
                    <v-icon left dark> mdi-shopping-search-outline </v-icon>
                    {{ $t("Beneficio_apuracao") }}
                  </v-btn>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>{{
                this.$t("Anexos")
              }}</v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-btn
                    :loading="loading"
                    @click="showDocumentacaoAcessoria(recurso)"
                    v-can="'ROLE_FuncionarioDocumentacaoAcessoria_List'"
                    class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                    height="39"
                  >
                    <v-icon left dark> mdi-text-box-search-outline </v-icon>
                    {{ $t("documentacaoacessoria") }}
                  </v-btn>

                  <v-btn
                    :loading="loading"
                    @click="showGed(recurso)"
                    v-can="'ROLE_FuncionarioGed_List'"
                    class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                    height="39"
                    v-if="possuiGed()"
                  >
                    <v-icon left dark> mdi-paperclip </v-icon>
                    {{ $t("GED") }}
                  </v-btn>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-col>
    </EditarBase>
  </div>
</template>

<script>
import EditarBase from "@/components/base/Crud/EditarBase";
import CampoTelefone from "@/components/base/proprios/CampoTelefone";
import CriaSuperSet from "@/components/base/proprios/CriaSuperset.vue";
import TabelaSelecionaRecurso from "@/components/base/proprios/TabelaSelecionaRecurso";
import ComumDataService from "@/services/ComumDataService";
import FuncionarioDataService from "@/services/pessoal/FuncionarioDataService";
import { VueMaskDirective } from "v-mask";
const DataService = FuncionarioDataService;
export default {
  name: "EditarFuncionario",
  components: {
    CampoTelefone,
    EditarBase,
    TabelaSelecionaRecurso,
    ComumDataService,
    CriaSuperSet,
    VueMaskDirective,
  },
  props: {
    recurso: Object,
  },
  data() {
    return {
      DataService: DataService,

      submited: false,
      matriculaAutomatica: false,
      foneCount: 1,

      //Modais
      modalAdmissao: false,
      modalCnhValidade: false,
      modalCnhEmissao: false,
      modalTerminoContrato: false,
      modalExperienciaTermino1: false,
      modalExperienciaTermino2: false,
      modalRgExpedicao: false,
      modalDtNascimento: false,
      modalGed: false,
      modalNewDocGed: false,

      newDocGed: {
        showModal: false,
        descricao: "",
        dataMinimaArmazenamentoFisico: null,
        matricula: "",
        file: null,
      },
      loading: false,
      //Tabelas
      estados: [],
      cidades: [],

      itensSindicato: [],
      modalHistoricoBeneficios: false,
      modalDocumentacaoAcessoria: false,
      headerHistoricoBeneficios: [
        {
          text: this.$t("Matricula"),
          value: "funcionario.matricula",
          sortable: "false",
        },
        {
          text: this.$t("Funcionario"),
          value: "funcionario.nomeSocial",
          sortable: "false",
        },
        {
          text: this.$t("Periodo"),
          value: "beneficioPeriodoApuracao.inicioPeriodo",
          sortable: "false",
        },
        {
          text: this.$t("Periodo"),
          value: "beneficioPeriodoApuracao.finalPeriodo",
          sortable: "false",
        },
        {
          text: this.$t("VA"),
          value: "vaTotal",
          sortable: "false",
        },
        {
          text: this.$t("VR"),
          value: "vrTotal",
          sortable: "false",
        },
        {
          text: this.$t("VT"),
          value: "vtTotal",
          sortable: "false",
        },
        {
          text: this.$t("Outros"),
          value: "outrosTotal",
          sortable: "false",
        },
        {
          text: this.$t("Total"),
          value: "valorTotal",
          sortable: "false",
        },
      ],
      listagemHistoricoBeneficios: [],

      modalBeneficiosOptante: false,
      headerBeneficiosOptante: [
        {
          text: this.$t("Convenio"),
          value: "beneficioConvenio.descricao",
          sortable: "false",
        },
        {
          text: this.$t("quantidadePorApuracao"),
          value: "quantidadePorApuracao",
          sortable: "false",
        },
        {
          text: this.$t("Tipo"),
          value: "beneficioConvenio.tipo",
          sortable: "false",
        },
        {
          text: this.$t("Valor_unitario"),
          value: "beneficioConvenio.valorUnitario",
          sortable: "false",
        },
        {
          text: this.$t("Inicio"),
          value: "inicio",
          sortable: "false",
        },
        {
          text: this.$t("Encerramento"),
          value: "encerramento",
          sortable: "false",
        },
      ],
      listagemBeneficiosOptante: [],

      headerDocumentacaoAcessoria: [
        {
          text: this.$t("Nome"),
          value: "funcionario.nomeSocial",
          sortable: "false",
        },

        {
          text: this.$t("Data"),
          value: "dtInclusao",
          sortable: "false",
        },

        {
          text: this.$t("Descricao"),
          value: "descricao",
          sortable: "false",
        },
        {
          text: this.$t("Assinado"),
          value: "assinado",
          sortable: "false",
        },
        { text: this.$t("Acoes"), value: "actions", sortable: false },
      ],

      headerGed: [
        {
          text: this.$t("Nome"),
          value: "funcionario.nomeSocial",
          sortable: "false",
        },

        {
          text: this.$t("Descricao"),
          value: "descricao",
          sortable: "false",
        },
        {
          text: this.$t("Data_de_inclusao"),
          value: "dtInclusao",
          sortable: "false",
        },
        {
          text: this.$t("Responsavel"),
          value: "reponsavel.nome",
          sortable: "false",
        },
        { text: this.$t("Acoes"), value: "actions", sortable: false },
      ],
      listagemDocumentacaoAcessoria: [],
      listagemGed: [],

      recurso: {
        //Informações da contratação
        admissao: "",
        cargo: { salarioBase: "", descricao: "" },
        contratoTermino: "",
        contratoTipo: 0,
        primeiroEmprego: false,

        //salarioBase: "",
        salarioUnidade: null,
        sindicato: {},
        status: null,
        tipoAdmissao: null,
        tipoJornada: null,
        experienciaTermino1: "",
        experienciaTermino2: "",
        matricula: "",

        dtAlteracao: "", // Devo mandar ?
        dtInclusao: "", //

        //Documentos
        ctpsNumero: "",
        ctpsSerie: "",
        ctpsUF: "",
        fgtsOptante: false,
        inssOptante: false,
        opcaoFGTS: false,
        reservistaCategoria: "",
        reservistaNumero: "",
        cnh: "",
        cnhCategoria: 0,
        cnhUF: {},
        cnhValidade: "",
        cnhEmissao: "",
        rg: "",
        rgExpedicao: "",
        rgUF: {},

        //Dados pessoais
        nome: "",
        email: "",
        raca: "",
        sexo: "",
        dtNascimento: "",
        instrucao: "",
        naturalidade: {},
        estadoCivil: "",
        grupoSanguineo: "",
        nomeMae: "",
        nomePai: "",
        nomeConjuge: "",
        nomeSocial: "",
        //Endereco
        pessoa: { cidade: {}, bairro: "", logradouro: "", telefones: [] },

        //Informações bancárias
        contaAgencia: "",
        contaBanco: "",
        contaNumero: "",
        contaOperacao: "",

        //Deficiencias
        cotaDeficiencia: false,
        defFisica: false,
        defIntelectual: false,
        defMental: false,
        defAuditiva: false,
        defVisual: false,
      },
      pessoas: [],
      pessoa: {},

      errors: [],

      itensTipoDependente: [
        { text: this.$t("CONJUGUE"), value: "CONJUGUE" },
        {
          text: this.$t("COMPANHEIROUNIAOESTAVEL"),
          value: "COMPANHEIROUNIAOESTAVEL",
        },

        { text: this.$t("FILHOENTEADO"), value: "FILHOENTEADO" },
        {
          text: this.$t("IRMAONETOBISNETOSEMARRIMO"),
          value: "IRMAONETOBISNETOSEMARRIMO",
        },
        { text: this.$t("PAISAVOSBISAVOS"), value: "PAISAVOSBISAVOS" },
        { text: this.$t("MENORGUARDAJUDICIAL"), value: "MENORGUARDAJUDICIAL" },
        { text: this.$t("PESSOAINCAPAZ"), value: "PESSOAINCAPAZ" },
        { text: this.$t("EXCONJUGUE"), value: "EXCONJUGUE" },
        { text: this.$t("AGREGADOOUTROS"), value: "AGREGADOOUTROS" },
      ],
    };
  },

  mounted() {
    //this.getPessoas();
    this.getCidades();
    this.getEstados();

    this.getFuncionarioSindicatos();
    //this.getRecurso();
  },

  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccess");
    },
    emitSubmitError() {
      this.$emit("submitError");
    },
    closeModalEdit() {
      this.$emit("closeModalEdit");
    },
    submitForm(event) {
      DataService.update(this.limpaMascara(this.recurso))
        .then((response) => {
          this.erros = [];
          this.submited = true;
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

          this.errors = error.response.data.errors;
        });
    },

    buscaCep(e) {
      if (e.target.value.length >= 8) {
        DataService.buscaCep(e.target.value).then((response) => {
          this.recurso.pessoa.logradouro = response.data.logradouro;
          this.recurso.pessoa.bairro = response.data.bairro;
          this.recurso.pessoa.cidade = this.cidades.find(
            (item) => item.nome == response.data.cidade
          );
        });
      }
    },

    getEstados() {
      DataService.getEstados()
        .then((response) => {
          this.estados = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCidades() {
      DataService.getCidades()
        .then((response) => {
          this.cidades = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getFuncionarioSindicatos() {
      DataService.getFuncionarioSindicatos()
        .then((response) => {
          this.itensSindicato = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    toggleMatriculaAutomatica(event) {
      if (event) {
        this.recurso.matricula = 0;
        this.matriculaAutomatica = true;
      } else {
        this.matriculaAutomatica = false;
      }
    },

    addFone() {
      this.foneCount++;
      this.recurso.pessoa.telefones.push("");
    },

    removeFone() {
      this.foneCount--;
      this.recurso.pessoa.telefones.pop();
    },

    getRecurso() {
      DataService.find(this.recurso.id)
        .then((response) => {
          this.recurso = response.data;
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(error);
          this.errors.push({ field: "ID", message: "não encontrado" });
        });
    },

    showHistoricoBeneficios(recurso) {
      console.log("showHistoricoBeneficios", recurso);
      this.modalHistoricoBeneficios = true;
      ComumDataService.get(
        "beneficioApuracao/funcionario/" + recurso.matricula,
        {
          params: {},
        }
      )
        .then((res) => {
          this.listagemHistoricoBeneficios = res.data;
          console.log(
            "listagemHistoricoBeneficios",
            this.listagemHistoricoBeneficios
          );
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    showBeneficiosOptante(recurso) {
      console.log("showHistoricoBeneficios", recurso);

      ComumDataService.get(
        "beneficioConvenioOptante/funcionario/" + recurso.id,
        {
          params: {},
        }
      )
        .then((res) => {
          this.listagemBeneficiosOptante = res.data;
          console.log(
            "listagemBeneficiosOptante",
            this.listagemBeneficiosOptante
          );
          this.loading = false;
          this.modalBeneficiosOptante = true;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    showDocumentacaoAcessoria(recurso) {
      this.loading = true;
      this.modalDocumentacaoAcessoria = true;
      ComumDataService.get(
        "funcionarioDocumentacaoAcessoria/matricula/" + recurso.matricula,
        {
          params: {},
        }
      )
        .then((res) => {
          this.listagemDocumentacaoAcessoria = res.data;
          // console.log(
          //   "listagemHistoricoBeneficios",
          //   this.listagemDocumentacaoAcessoria
          // );
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    showGed() {
      this.reloadFilesGed();
      this.modalGed = true;
    },

    reloadFilesGed() {
      ComumDataService.get(
        "funcionarioGed/matricula/" + this.recurso.matricula,
        {
          params: {},
        }
      )
        .then((res) => {
          this.listagemGed = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    printSingle(recurso) {
      this.loading = true;

      ComumDataService.get(
        "funcionarioDocumentacaoAcessoria/print/" + recurso.id,
        {
          responseType: "blob",
        }
      )
        .then((res) => {
          this.loading = false;
          this.download(res.data, "document.pdf", "application/pdf");
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Documento_gerado_com_sucesso"),
            "success"
          );
        })
        .catch((error) => {
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Falha_ao_exportar"),
            "error"
          );
        });
    },

    download(content, fileName, mimeType) {
      var a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";

      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && "download" in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        location.href =
          "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
      }
    },

    getPessoas() {
      ComumDataService.get("/pessoas").then((res) => {
        this.pessoas = res.data;
      });
    },

    possuiGed() {
      try {
        let param = this.$store.state.inquilino.modulosContratacao.find(
          (item) => {
            return item.tipo == "GED";
          }
        );
        if (param != undefined && param.id != undefined) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },

    newDocGedFunction() {
      this.$refs.form.validate();
      if (this.descricao.length === 0) {
        alert("O campo 'Descrição' é obrigatório.");
        return;
      }

      if (this.descricao.length < 5) {
        alert("O campo 'Descrição' deve ter pelo menos 5 caracteres.");
        return;
      }

      if (this.descricao.length > 50) {
        alert("O campo 'Descrição' deve ter no máximo 50 caracteres.");
        return;
      }

      if (this.file === null) {
        alert("O campo 'Arquivo' é obrigatório.");
        return;
      }

      // Valida o tamanho do arquivo
      if (this.file.size > 3145728) {
        alert("O arquivo não pode exceder 3.0 megabyte.");
        return;
      }

      this.loading = true;

      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("matricula", this.recurso.matricula);
      formData.append("descricao", this.descricao);

      ComumDataService.post("funcionarioGed", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          this.reloadFilesGed();
          this.loading = false;
          this.closeModalNewGed();
        })
        .catch((err) => {
          this.loading = false;
          alert(err.response.data.message);
        });
    },
    closeModalNewGed() {
      this.modalNewDocGed = false;
      this.newDocGed.descricao = "";
      this.newDocGed.dataMinimaArmazenamentoFisico = null;
      this.newDocGed.matricula = "";
      this.newDocGed.file = null;
    },

    changeFile(event) {
      this.file = event;
      console.log("this.newDocGed.file", this.file);
    },

    downloadAnexo(url) {
      window.open(url, "_blank");
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", ""); // O atributo 'download' indica que é um link de download
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    },

    deleteAnexo(obj) {
      this.loading = true;
      ComumDataService.delete("funcionarioGed/" + obj.id, {})
        .then(() => {
          this.reloadFilesGed();
          this.loading = false;
          //this.closeModalNewGed();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Excluido_com_sucesso"),
            "success"
          );
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          alert(err.response.data.message);
        });
    },

    possuiParametrizacaoInquilino(parametrizacao) {
      return this.$store.state.inquilino.parametrizacoes.some(
        (item) => item.tipo == parametrizacao
      );
    },
  },
};
</script>

<style scoped></style>
