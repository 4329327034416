import Axios from "axios";

class UsuarioDataService {

  updateSenha(params) {
    return Axios.put('/usuarios/profile', { id: params.id, senha: params.senha })
  }

  getPessoas() {
    return Axios.get('/pessoas')
  }

  getUser() {
    return Axios.get('usuarios/dados_Usuario')
  }


  getUserSimplified() {
    return Axios.get('usuarios/dados_Usuario/simplified')
  }

  getAll() {
    return Axios.get('usuarios')
  }

  getPermissoes() {
    return Axios.get('usuarios/permissoes')
  }

  update(params) {
    return Axios.put(`usuarios/${params.id}`, params)
  }

  create(params) {
    return Axios.post('usuarios', params)
  }

  getPage(params) {
    return Axios.get('usuarios/page', { params })
  }

  delete(id) {
    return Axios.delete(`usuarios/${id}`);
  }

  find(id) {
    return Axios.get(`usuarios/${id}`)
  }

  findPermissoes() {
    return Axios.get('usuarios/permissoes')
  }

  findPerfis() {
    return Axios.get('usuarios/perfis')
  }

  getFilter(params) {
    return Axios.post("usuarios/filter", params);
  }
}

export default new UsuarioDataService()
