<template>
  <v-app class="bg-white">
    <!-- <v-container class="position-sticky top-0 z-index-2 py-0">
      <v-row>
        <v-col cols="12" class="py-0">
          <app-bar-blur-auth> </app-bar-blur-auth>
        </v-col>
      </v-row>
    </v-container> -->

    <v-container>
      <v-col lg="4" md="6" class="d-flex flex-column mx-lg-0 mx-auto">
        <Transition>
          <div>
            <!-- inicia aqui -->
            <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
              <div class="card-padding pb-0">
                <h4 class="text-h4 font-weight-bolder text-typo mb-2">Login</h4>
                <p class="mb-0 text-body">
                  {{ $t("Informe_seus_dados_ordem_abaixo") }}
                </p>
              </div>
              <form @submit.prevent="login">
                <div v-for="error in errors" :key="error.id">
                  <base-material-alert color="error" dark dismissible>
                    <span>{{ error.fieldName }}</span> {{ error.message }}
                  </base-material-alert>
                </div>
                <div class="card-padding pb-4">
                  <label class="text-xs text-typo font-weight-bolder ms-1">
                    {{ $t("Email_cpf") }}</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    background-color="rgba(255,255,255,.9)"
                    color="rgba(0,0,0,.6)"
                    light
                    height="40"
                    v-model="cliente.email"
                    :placeholder="$t('Email_cpf')"
                    class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2"
                  >
                  </v-text-field>

                  <label class="text-xs text-typo font-weight-bolder ms-1">{{
                    $t("Senha")
                  }}</label>
                  <v-text-field
                    hide-details
                    outlined
                    background-color="rgba(255,255,255,.9)"
                    color="rgba(0,0,0,.6)"
                    light
                    height="40"
                    v-model="cliente.senha"
                    :placeholder="$t('Senha')"
                    type="password"
                    class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2"
                  >
                  </v-text-field>

                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="39"
                    type="submit"
                    class="font-weight-bold text-uppercase btn-primary bg-gradient-primary py-2 px-6 me-2 mt-7 mb-2 w-100"
                    color="#5e72e4"
                    small
                    >Login</v-btn
                  >
                </div>
              </form>
              <div class="text-center">
                <p class="text-sm text-body mt-3 mb-0">
                  {{ $t("Esqueceu_a_senha?") }}
                  <a
                    href="recuperar-senha"
                    class="text-primary text-gradient text-decoration-none font-weight-bold"
                  >
                    {{ $t("Clique_aqui") }}</a
                  >
                </p>

                <div class="text-sm text-body mt-3 mb-0">
                  <select v-model="$i18n.locale">
                    <option
                      v-for="(lang, i) in langs"
                      :key="`Lang${i}`"
                      :value="lang.item"
                    >
                      {{ lang.description }}
                    </option>
                  </select>
                </div>
              </div>
            </v-card>
            <!-- finaliza aqui -->
          </div>
        </Transition>
      </v-col>
    </v-container>
    <v-col md="6">
      <div
        class="oblique position-absolute top-0 h-100 d-md-block d-none me-n8"
      >
        <div
          class="oblique-image position-absolute fixed-top ms-auto h-100 w-100 z-index-0 ms-n16"
          :style="`background-image: url(${require('../../assets/img/curved-images/curved15.jpg')}`"
        >
          <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
            <v-img
              :src="logoPath"
              alt="rocket"
              :max-width="logoWhidt"
              class="imgCajupe"
            >
            </v-img>
            <div class="card-padding pb-0">
              <h4 class="textLogin">
                {{ $t("Sua_jornada_inicia_aqui") }}
              </h4>
              <!-- <h4 class="text-align-center mt-10 text-white mb-2">
                {{ $t("Pucos_cliques_mundo_possibilidades") }}
              </h4> -->
            </div>
          </v-card>
        </div>
      </div>
    </v-col>
    <!-- <content-footer auth v-if="!$route.meta.hideFooter"></content-footer> -->
  </v-app>
</template>
<script>
//import { Transition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";
export default {
  name: "page-layout",
  components: { ContentFooter },
  data() {
    return {
      errors: [],
      langs: [
        {
          item: "pt",
          description: "Português (Brasil)",
        },
        {
          item: "en",
          description: "English",
        },
        {
          item: "es",
          description: "Español",
        },
      ],
      cliente: {
        email: "",
        senha: "",
      },
    };
  },
  methods: {
    login: function () {
      this.$store
        .dispatch("AUTH_REQUEST", this.limpaMascara(this.cliente))
        .then(() => {
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          console.log(error);
          this.errors.push({
            message: this.$t("Usuario_ou_senha_invalidos"),
            field: this.$t("Usuario_ou_senha_invalidos"),
          });
        });
    },
  },

  computed: {
    maskUsername: function () {
      let value = this.cliente.email;

      //if value includes a letter
      if (value.match(/[a-z]/i)) {
        return null;
      } else {
        return "###.###.###-##";
      }
    },
    // Computed property to determine which logo to show
    logoPath() {
      // Check if the current route is "LoginPreserve"
      return this.$route.name === "LoginPreserve"
        ? require("@/assets/LogoPreserve.png")
        : require("@/assets/Cajupe_simples.png");
    },

    logoWhidt() {
      // Check if the current route is "LoginPreserve"
      return this.$route.name === "LoginPreserve" ? 350 : 200;
    },
  },
};
</script>
<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.9s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.imgCajupe {
  position: relative;
  top: 150px;
  left: 410px;
}

.textLogin {
  font-size: 30px;
  position: relative;
  top: 160px;
  left: 320px;
}
</style>