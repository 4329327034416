import Vue from "vue";
import VueRouter from "vue-router";
import AuthIllustrationLayout from "../views/Layout/AuthIllustrationLayout";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import PageLayout from "../views/Layout/PageLayout";

import EsqueceuSenha from "@/components/EsqueceuSenha";
import CadastroUsuario from "@/components/acessos/Usuarios/CadastroUsuario";
import EditarUsuario from "@/components/acessos/Usuarios/EditarUsuario";
import ListagemUsuarios from "@/components/acessos/Usuarios/ListagemUsuarios";
import PerfilUsuario from "@/components/acessos/Usuarios/PerfilUsuario";
import CadastroPais from "@/components/locais/paises/CadastroPais";
import EditarPais from "@/components/locais/paises/EditarPais";
import ListagemPaises from "@/components/locais/paises/ListagemPaises";
import EditarPedido from "@/components/operacoes/pedidos/EditarPedido";
import ListagemPedidos from "@/components/operacoes/pedidos/ListagemPedidos";
import CadastroFuncionarioCargo from "@/components/pessoal/funcionario-cargo/CadastroFuncionarioCargo";
import EditarFuncionarioCargo from "@/components/pessoal/funcionario-cargo/EditarFuncionarioCargo";
import ListagemFuncionarioCargos from "@/components/pessoal/funcionario-cargo/ListagemFuncionarioCargos";
import EditarFuncionarioFolhaPagamentoVerba from "@/components/pessoal/funcionario-folha-pagamento-verba/EditarFuncionarioFolhaPagamentoVerba";
import ListagemFuncionarioFolhaPagamentoVerba from "@/components/pessoal/funcionario-folha-pagamento-verba/ListagemFuncionarioFolhaPagamentoVerba";
import CadastroFuncionarioPonto from "@/components/pessoal/funcionario-ponto/CadastroFuncionarioPonto";
import EditarFuncionarioPonto from "@/components/pessoal/funcionario-ponto/EditarFuncionarioPonto";
import ListagemFuncionarioPontos from "@/components/pessoal/funcionario-ponto/ListagemFuncionarioPontos";
import CadastroFuncionarioSindicato from "@/components/pessoal/funcionario-sindicato/CadastroFuncionarioSindicato";
import EditarFuncionarioSindicato from "@/components/pessoal/funcionario-sindicato/EditarFuncionarioSindicato";
import ListagemFuncionarioSindicatos from "@/components/pessoal/funcionario-sindicato/ListagemFuncionarioSindicatos";
// import EditarFuncionarioUniforme from "@/components/pessoal/funcionario-uniforme/EditarUniforme";
// import ListagemFuncionarioUniforme from "@/components/pessoal/funcionario-uniforme/ListagemUniforme";
import EditarFuncionario from "@/components/pessoal/funcionarios/EditarFuncionario";
import ListagemFuncionarios from "@/components/pessoal/funcionarios/ListagemFuncionarios";
import CadastroPedidoConsumivel from "@/components/recursos/pedido-consumivel/CadastroPedido";
import EditarPedidoConsumivel from "@/components/recursos/pedido-consumivel/EditarPedido";
import ListagemPedidoConsumivel from "@/components/recursos/pedido-consumivel/ListagemPedidos";
import CadastroPedidoMaterial from "@/components/recursos/pedido-material/CadastroPedido";
import EditarPedidoMaterial from "@/components/recursos/pedido-material/EditarPedido";
import ListagemPedidoMaterial from "@/components/recursos/pedido-material/ListagemPedidos";
import CadastroPedidoUniforme from "@/components/recursos/pedido-uniforme/CadastroPedido";
import EditarPedidoUniforme from "@/components/recursos/pedido-uniforme/EditarPedido";
import ListagemPedidoUniforme from "@/components/recursos/pedido-uniforme/ListagemPedidos";
import CadastroInquilino from "@/components/software/inquilinos/CadastroInquilino";
import EditarInquilino from "@/components/software/inquilinos/EditarInquilino";
import ListagemInquilinos from "@/components/software/inquilinos/ListagemInquilinos";
import store from "@/store";

import CadastroAvisoPrevio from "@/components/pessoal/funcionario-aviso-previo/CadastroAvisoPrevio";
import EditarAvisoPrevio from "@/components/pessoal/funcionario-aviso-previo/EditarAvisoPrevio";
import ListagemAvisoPrevio from "@/components/pessoal/funcionario-aviso-previo/ListagemAvisoPrevio";


import CadastroModeloDocumentacaoAcessoria from "@/components/pessoal/modelo-documentacao-acessoria/CadastroModeloDocumentacaoAcessoria";
import EditarModeloDocumentacaoAcessoria from "@/components/pessoal/modelo-documentacao-acessoria/EditarModeloDocumentacaoAcessoria";
import ListagemModeloDocumentacaoAcessoria from "@/components/pessoal/modelo-documentacao-acessoria/ListagemModeloDocumentacaoAcessoria";

import ListageOrcamentoDespesa from "@/components/financeiro/orcamento-despesa/ListagemOrcamentoDespesa";
// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");



const Alerts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Alerts.vue");
const Notifications = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Notifications.vue");


Vue.use(VueRouter);
const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("dashboard");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("login");
};

const routes = [
  {
    path: "/",
    component: PageLayout,
    name: "landing",
    children: [
      {
        path: "/",
        name: "Landing",
        component: () => import("@/views/Landing/Index")
      },
    ],
  },
  {
    path: "/",
    component: AuthIllustrationLayout,
    name: "Authentication Illustration",
    children: [
      {
        path: "/login",
        name: "Login",
        component: import("@/components/Login.vue"),
        beforeEnter: ifNotAuthenticated
      },
      {
        path: "/recuperar-senha",
        name: "Esqueceu.senha",
        component: EsqueceuSenha,
        beforeEnter: ifNotAuthenticated
      },
      {
        path: "/login_preserve",
        name: "LoginPreserve",
        component: import("@/components/LoginPreserve.vue"),
        beforeEnter: ifNotAuthenticated
      },
    ],
  },

  {
    path: "/",
    name: "Dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "Welcome",
        component: Dashboard,
        meta: {
          groupName: "Cajupe",
        },
      },
      {
        name: "User Profile",
        path: "dashboard/pages/user",
        component: () => import("@/views/Dashboard/pages/UserProfile"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Cajupe",
        },
      },
      {
        name: "Notifications",
        path: "dashboard/components/notifications",
        component: () => import("@/views/Dashboard/component/Notifications"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Cajupe",
        },
      },
      {
        name: "Icons",
        path: "dashboard/components/icons",
        component: () => import("@/views/Dashboard/component/Icons"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Cajupe",
        },
      },
      {
        name: "Typography",
        path: "dashboard/components/typography",
        component: () => import("@/views/Dashboard/component/Typography"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Cajupe",
        },
      },
      // Tables
      {
        name: "Regular Tables",
        path: "dashboard/tables/regular-tables",
        component: () => import("@/views/Dashboard/tables/RegularTables"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Cajupe",
        },
      },
      // Maps
      {
        name: "Google Maps",
        path: "dashboard/maps/google-maps",
        component: () => import("@/views/Dashboard/maps/GoogleMaps"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Cajupe",
        },
      },
      // Upgrade
      {
        name: "Upgrade",
        path: "dashboard/upgrade",
        component: () => import("@/views/Dashboard/Upgrade"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Cajupe",
        },
      },
      {
        name: "clientes",
        path: "dashboard/clientes",
        component: () =>
          import("@/components/comercial/clientes/ListagemClientes"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },

      {
        name: "cadastro_cliente",
        path: "dashboard/clientes/cadastro",
        component: () =>
          import("@/components/comercial/clientes/CadastroCliente"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "editar.cliente",
        path: "dashboard/clientes/:id/editar",
        component: () =>
          import("@/components/comercial/clientes/EditarCliente"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "Usuário",
        path: "dashboard/usuario",
        component: PerfilUsuario,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Perfil ",
        },
      },
      {
        name: "cadastro.usuario",
        path: "dashboard/usuarios/cadastro",
        component: CadastroUsuario,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Acessos",
        },
      },
      {
        name: "Usuarios",
        path: "dashboard/usuarios",
        component: ListagemUsuarios,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Acessos",
        },
      },
      {
        name: "editar.usuario",
        path: "dashboard/usuarios/:id/editar",
        component: EditarUsuario,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Acessos",
        },
      },
      {
        name: "Pedidos",
        path: "dashboard/pedidos",
        component: ListagemPedidos,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "cadastro.pedido",
        path: "dashboard/pedidos/cadastro",
        component: () =>
          import("@/components/operacoes/pedidos/CadastroPedido"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "editar.pedido",
        path: "dashboard/pedidos/:id/editar",
        component: EditarPedido,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },

      // {
      //   name: "Uniformes",
      //   path: "dashboard/uniformes",
      //   component: ListagemFuncionarioUniforme,
      //   beforeEnter: ifAuthenticated
      // },
      // {
      //   name: "cadastro.uniforme",
      //   path: "dashboard/funcionariouniformes/cadastro",
      //   component: () =>
      //     import("@/components/pessoal/funcionario-uniforme/CadastroUniforme"),
      //   beforeEnter: ifAuthenticated
      // },
      // {
      //   name: "editar.funcionariouniforme",
      //   path: "dashboard/uniformes/:id/editar",
      //   component: EditarFuncionarioUniforme,
      //   beforeEnter: ifAuthenticated
      // },

      {
        name: "Inquilinos",
        path: "dashboard/inquilinos",
        component: ListagemInquilinos,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Software",
        },
      },
      {
        name: "Cadastro.inquilino",
        path: "dashboard/inquilinos/cadastro",
        component: CadastroInquilino,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Software",
        },
      },
      {
        name: "editar.inquilino",
        path: "dashboard/inquilinos/:id/editar",
        component: EditarInquilino,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Software",
        },
      },
      {
        name: "configuracoes.inquilino",
        path: "dashboard/inquilinos/configuracoes",
        component: () => import("@/components/software/inquilinos/ConfiguracoesInquilino"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Configurações",
        },
      },
      {
        name: "FuncionarioSindicatos",
        path: "dashboard/funcionariosindicatos",
        component: ListagemFuncionarioSindicatos,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "Cadastro.FuncionarioSindicato",
        path: "dashboard/funcionariosindicatos/cadastro",
        component: CadastroFuncionarioSindicato,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "editar.funcionariosindicato",
        path: "dashboard/funcionariosindicatos/:id/editar",
        component: EditarFuncionarioSindicato,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "FuncionarioCargos",
        path: "dashboard/funcionariocargos",
        component: ListagemFuncionarioCargos,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "cadastro.funcionariocargo",
        path: "dashboard/funcionariocargos/cadastro",
        component: CadastroFuncionarioCargo,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "editar.funcionariocargo",
        path: "dashboard/funcionariocargos/:id/editar",
        component: EditarFuncionarioCargo,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "FuncionarioFolhaPagamentoVerbas",
        path: "dashboard/funcionarioFolhaPagamentoVerbas",
        component: ListagemFuncionarioFolhaPagamentoVerba,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "editar.funcionariofolhapagamentoverba",
        path: "dashboard/funcionariofolhapagamentoverba/:id/editar",
        component: EditarFuncionarioFolhaPagamentoVerba,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "Funcionarios",
        path: "dashboard/funcionarios",
        component: ListagemFuncionarios,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "Cadastro.Funcionario",
        path: "dashboard/funcionarios/cadastro",
        component: () => import("@/components/pessoal/funcionarios/CadastroFuncionario"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "Cadastro.FuncionarioResumed",
        path: "dashboard/funcionarios/cadastro/resume",
        component: () => import("@/components/pessoal/funcionarios-resume/CadastroFuncionarioResume"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "editar.funcionario",
        path: "dashboard/funcionarios/:id/editar",
        component: EditarFuncionario,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "FuncionarioPontos",
        path: "dashboard/funcionariopontos",
        component: ListagemFuncionarioPontos,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },
      {
        name: "Cadastro.FuncionarioPonto",
        path: "dashboard/funcionariopontos/cadastro",
        component: CadastroFuncionarioPonto,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },
      {
        name: "editar.funcionarioponto",
        path: "dashboard/funcionariopontos/:id/editar",
        component: EditarFuncionarioPonto,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },
      {
        name: "Paises",
        path: "dashboard/paises",
        component: ListagemPaises,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Locais",
        },
      },
      {
        name: "Cadastro.Pais",
        path: "dashboard/paises/cadastro",
        component: CadastroPais,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Locais",
        },
      },
      {
        name: "editar.pais",
        path: "dashboard/paises/:id/editar",
        component: EditarPais,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Locais",
        },
      },
      {
        name: "Pessoas",
        path: "dashboard/pessoas",
        component: () =>
          import("@/components/pessoal/pessoas/ListagemPessoas"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "Cadastro.Pessoa",
        path: "dashboard/pessoas/cadastro",
        component: () =>
          import("@/components/pessoal/pessoas/CadastroPessoa"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "editar.pessoa",
        path: "dashboard/pessoas/:id/editar",
        component: () => import("@/components/pessoal/pessoas/EditarPessoa"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "Estados",
        path: "dashboard/estados",
        component: () =>
          import("@/components/locais/estados/ListagemEstados"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Locais",
        },
      },
      {
        name: "Cadastro.estado",
        path: "dashboard/estados/cadastro",
        component: () => import("@/components/locais/estados/CadastroEstado"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Locais",
        },
      },
      {
        name: "editar.estado",
        path: "dashboard/estados/:id/editar",
        component: () => import("@/components/locais/estados/EditarEstado"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Locais",
        },
      },
      {
        name: "Cidades",
        path: "dashboard/cidades",
        component: () =>
          import("@/components/locais/cidades/ListagemCidades"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Locais",
        },
      },
      {
        name: "Cadastro.cidade",
        path: "dashboard/cidades/cadastro",
        component: () => import("@/components/locais/cidades/CadastroCidade"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Locais",
        },
      },
      {
        name: "editar.cidade",
        path: "dashboard/cidades/:id/editar",
        component: () => import("@/components/locais/cidades/EditarCidade"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Locais",
        },
      },
      {
        name: "Veiculos",
        path: "dashboard/veiculos",
        component: () =>
          import("@/components/operacoes/veiculos/ListagemVeiculos"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "cadastro.veiculos",
        path: "dashboard/veiculos/cadastro",
        component: () =>
          import("@/components/operacoes/veiculos/CadastroVeiculo"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "editar.veiculo",
        path: "dashboard/veiculos/:id/editar",
        component: () =>
          import("@/components/operacoes/veiculos/EditarVeiculo"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "VeiculoModelos",
        path: "dashboard/veiculoModelos",
        component: () =>
          import(
            "@/components/operacoes/veiculo-modelos/ListagemVeiculoModelos"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "cadastro.VeiculoModelos",
        path: "dashboard/veiculoModelos/cadastro",
        component: () =>
          import(
            "@/components/operacoes/veiculo-modelos/CadastroVeiculoModelo"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "editar.veiculomodelo",
        path: "dashboard/veiculoModelos/:id/editar",
        component: () =>
          import("@/components/operacoes/veiculo-modelos/EditarVeiculoModelo"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "Roteiros",
        path: "dashboard/roteiros",
        component: () =>
          import("@/components/operacoes/roteiros/ListagemRoteiros"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "cadastro.roteiro",
        path: "dashboard/roteiros/cadastro",
        component: () =>
          import("@/components/operacoes/roteiros/CadastroRoteiro"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "editar.roteiro",
        path: "dashboard/roteiros/:id/editar",
        component: () =>
          import("@/components/operacoes/roteiros/EditarRoteiro"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "RoteiroModelos",
        path: "dashboard/roteiroModelos",
        component: () =>
          import("@/components/operacoes/roteiroModelos/ListagemRoteiroModelo"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "cadastro.roteiroModelos",
        path: "dashboard/roteiroModelos/cadastro",
        component: () =>
          import("@/components/operacoes/roteiroModelos/CadastroRoteiroModelo"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "editar.roteiromodelo",
        path: "dashboard/roteiroModelos/:id/editar",
        component: () =>
          import("@/components/operacoes/roteiroModelos/EditarRoteiroModelo"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "AcompanhamentoTrajetos",
        path: "dashboard/acompanhamento/trajetos",
        component: () =>
          import("@/components/operacoes/roteiroTrajeto/ListagemRoteiroTrajeto"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "GuiaTransporte",
        path: "dashboard/guiaTransportes",
        component: () =>
          import("@/components/operacoes/guiaTransporte/ListagemGuiaTransporte"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "Suprimentos",
        path: "dashboard/suprimentos",
        component: () =>
          import("@/components/operacoes/tesouraria/Suprimento"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Tesouraria",
        },
      },
      {
        name: "cadastro.suprimento",
        path: "dashboard/suprimentos/cadastro",
        component: () =>
          import("@/components/operacoes/tesouraria/CadastroSuprimento"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Tesouraria",
        },
      },
      {
        name: "editar.suprimento",
        path: "dashboard/suprimentos/:id/editar",
        component: () =>
          import("@/components/operacoes/tesouraria/EditarSuprimento"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Tesouraria",
        },
      },
      {
        name: "Questionarios",
        path: "dashboard/questionarios",
        component: () =>
          import(
            "@/components/operacoes/questionarios/ListagemQuestionarios"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "cadastro.questionario",
        path: "dashboard/questionarios/cadastro",
        component: () =>
          import("@/components/operacoes/questionarios/CadastroQuestionario"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "editar.questionario",
        path: "dashboard/questionarios/:id/editar",
        component: () =>
          import("@/components/operacoes/questionarios/EditarQuestionario"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "Produtos",
        path: "dashboard/produtos",
        component: () =>
          import("@/components/recursos/produtos/ListagemProdutos"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "cadastro.produto",
        path: "dashboard/produtos/cadastro",
        component: () =>
          import("@/components/recursos/produtos/CadastroProduto"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "editar.produto",
        path: "dashboard/produtos/:id/editar",
        component: () =>
          import("@/components/recursos/produtos/EditarProduto"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "ProdutoCategorias",
        path: "dashboard/produtoCategorias",
        component: () =>
          import(
            "@/components/recursos/produto-categorias/ListagemProdutoCategorias"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "cadastro.produtoCategoria",
        path: "dashboard/produtoCategorias/cadastro",
        component: () =>
          import(
            "@/components/recursos/produto-categorias/CadastroProdutoCategoria"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Cajupe",
        },
      },
      {
        name: "editar.produtoCategoria",
        path: "dashboard/produtoCategorias/:id/editar",
        component: () =>
          import(
            "@/components/recursos/produto-categorias/EditarProdutoCategoria"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Cajupe",
        },
      },
      {
        name: "ContaBancarias",
        path: "dashboard/contaBancarias",
        component: () =>
          import(
            "@/components/financeiro/conta-bancarias/ListagemContaBancarias"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "cadastro.contaBancaria",
        path: "dashboard/contaBancarias/cadastro",
        component: () =>
          import(
            "@/components/financeiro/conta-bancarias/CadastroContaBancaria"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "editar.contabancaria",
        path: "dashboard/contaBancarias/:id/editar",
        component: () =>
          import(
            "@/components/financeiro/conta-bancarias/EditarContaBancaria"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "extrato.contabancaria",
        path: "dashboard/contaBancarias/extrato",
        component: () =>
          import(
            "@/components/financeiro/conta-bancarias/Extrato"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },

      {
        name: "fluxoDiario",
        path: "dashboard/financeiro/fluxoDiario",
        component: () =>
          import(
            "@/components/financeiro/analises/FluxoDiario"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },

      {
        name: "dfc.direto",
        path: "dashboard/financeiro/dfc/direto",
        component: () =>
          import(
            "@/components/financeiro/analises/dfc/DFC"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },

      {
        name: "analiseCC",
        path: "dashboard/financeiro/analiseCC/analise",
        component: () =>
          import(
            "@/components/financeiro/analises/analiseCC/Analise"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },

      {
        name: "CentroCustos",
        path: "dashboard/centroCustos",
        component: () =>
          import(
            "@/components/financeiro/centro-custos/ListagemCentroCustos"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "CentroCustosAnalitico",
        path: "dashboard/centroCustosAnalitico",
        component: () =>
          import(
            "@/components/financeiro/centro-custos/ListagemCentroCustosAnalitico"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },

      {
        name: "cadastro.centroCusto",
        path: "dashboard/centroCustos/cadastro",
        component: () =>
          import("@/components/financeiro/centro-custos/CadastroCentroCusto"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "editar.centrocusto",
        path: "dashboard/centroCustos/:id/editar",
        component: () =>
          import("@/components/financeiro/centro-custos/EditarCentroCusto"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },

      {
        name: "ContaBancariaTransferencias",
        path: "dashboard/contaBancariaTransferencias",
        component: () =>
          import(
            "@/components/financeiro/conta-bancaria-transferencia/ListagemContaBancariaTransferencia"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },

      {
        name: "cadastro.ContaBancariaTransferencia",
        path: "dashboard/contaBancariaTransferencias/cadastro",
        component: () =>
          import("@/components/financeiro/conta-bancaria-transferencia/CadastroContaBancariaTransferencia"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "editar.contabancariatransferencia",
        path: "dashboard/contaBancariaTransferencias/:id/editar",
        component: () =>
          import("@/components/financeiro/conta-bancaria-transferencia/EditarContaBancariaTransferencia"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },

      {
        name: "Fornecedores",
        path: "dashboard/fornecedores",
        component: () =>
          import("@/components/financeiro/fornecedores/ListagemFornecedores"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "cadastro.fornecedor",
        path: "dashboard/fornecedores/cadastro",
        component: () =>
          import("@/components/financeiro/fornecedores/CadastroFornecedor"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "editar.fornecedor",
        path: "dashboard/fornecedores/:id/editar",
        component: () =>
          import("@/components/financeiro/fornecedores/EditarFornecedor"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },

      {
        name: "TituloReceber",
        path: "dashboard/tituloReceber",
        component: () =>
          import(
            "@/components/financeiro/titulo-receber/ListagemTituloReceber"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "TituloReceberPending",
        path: "dashboard/tituloReceberPending",
        component: () =>
          import(
            "@/components/financeiro/titulo-receber/ListagemTituloReceberPending"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "cadastro.tituloReceber",
        path: "dashboard/tituloreceber/cadastro",
        component: () =>
          import(
            "@/components/financeiro/titulo-receber/CadastroTituloReceber"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "editar.tituloreceber",
        path: "dashboard/tituloreceber/:id/editar",
        component: () =>
          import(
            "@/components/financeiro/titulo-receber/EditarTituloReceber"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },

      {
        name: "RegistroRecebimentos",
        path: "dashboard/registroRecebimentos",
        component: () =>
          import(
            "@/components/financeiro/registro-recebimentos/ListagemRegistroRecebimentos"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "cadastro.registroRecebimentos",
        path: "dashboard/registroRecebimentos/cadastro",
        component: () =>
          import(
            "@/components/financeiro/registro-recebimentos/CadastroRegistroRecebimento"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "editar.registrorecebimento",
        path: "dashboard/registroRecebimentos/:id/editar",
        component: () =>
          import(
            "@/components/financeiro/registro-recebimentos/EditarRegistroRecebimento"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },

      {
        name: "TituloPagar",
        path: "dashboard/tituloPagar",
        component: () =>
          import("@/components/financeiro/titulo-pagar/ListagemTituloPagar"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "TituloPagarPending",
        path: "dashboard/tituloPagarPending",
        component: () =>
          import("@/components/financeiro/titulo-pagar/ListagemTituloPagarPending"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "cadastro.tituloPagar",
        path: "dashboard/tituloPagar/cadastro",
        component: () =>
          import("@/components/financeiro/titulo-pagar/CadastroTituloPagar"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "editar.titulopagar",
        path: "dashboard/tituloPagar/:id/editar",
        component: () =>
          import("@/components/financeiro/titulo-pagar/EditarTituloPagar"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },

      {
        name: "RegistroPagamentos",
        path: "dashboard/registroPagamentos",
        component: () =>
          import(
            "@/components/financeiro/registro-pagamentos/ListagemRegistroPagamentos"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "cadastro.registroPagamentos",
        path: "dashboard/registroPagamentos/cadastro",
        component: () =>
          import(
            "@/components/financeiro/registro-pagamentos/CadastroRegistroPagamento"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "editar.registropagamento",
        path: "dashboard/registroPagamentos/:id/editar",
        component: () =>
          import(
            "@/components/financeiro/registro-pagamentos/EditarRegistroPagamento"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },

      {
        name: "FuncionarioPontoCalculo",
        path: "dashboard/funcionarioPontoCalculos",
        component: () =>
          import(
            "@/components/pessoal/funcionario-ponto/ListagemFuncionarioPontoCalculo"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },
      {
        name: "FuncionarioPontoPeriodo",
        path: "dashboard/funcionarioPontoPeriodos",
        component: () =>
          import(
            "@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo/ListagemFuncionarioPontoPeriodos"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },
      {
        name: "TutorialFuncionarioPontoPeriodo",
        path: "dashboard/tutorial/funcionarioPontoPeriodos",
        component: () =>
          import(
            "@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo/TutorialListagemFuncionarioPontoPeriodos"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },
      {
        name: "cadastro.funcionarioPontoPeriodo",
        path: "dashboard/funcionarioPontoPeriodos/cadastro",
        component: () =>
          import(
            "@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo/CadastroFuncionarioPontoPeriodo"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },
      {
        name: "editar.funcionariopontoperiodo",
        path: "dashboard/funcionarioPontoPeriodos/:id/editar",
        component: () =>
          import(
            "@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo/EditarFuncionarioPontoPeriodo"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },
      {
        name: "tutorial.editar.funcionariopontoperiodo",
        path: "dashboard/tutorial/funcionarioPontoPeriodos/:id/editar",
        component: () =>
          import(
            "@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo/TutorialEditarFuncionarioPontoPeriodo"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },
      {
        name: "FuncionarioPontoPeriodoReducaoCargaHorariaColetiva",
        path: "dashboard/funcionarioPontoPeriodoReducaoCargaHorariaColetiva",
        component: () =>
          import(
            "@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo-reducao-carga-horaria-coletiva/ListagemFuncionarioPontoPeriodoReducaoCargaHorariaColetiva"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },
      {
        name: "cadastro.funcionarioPontoPeriodoReducaoCargaHorariaColetiva",
        path: "dashboard/funcionarioPontoPeriodoReducaoCargaHorariaColetivas/cadastro",
        component: () =>
          import(
            "@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo-reducao-carga-horaria-coletiva/CadastroFuncionarioPontoPeriodoReducaoCargaHorariaColetiva"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },
      {
        name: "editar.funcionariopontoperiodoreducaocargahorariacoletiva",
        path: "dashboard/funcionarioPontoPeriodoReducaoCargaHorariaColetivas/:id/editar",
        component: () =>
          import(
            "@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo-reducao-carga-horaria-coletiva/EditarFuncionarioPontoPeriodoReducaoCargaHorariaColetiva"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },
      {
        name: "ParametrizacaoFiscal",
        path: "dashboard/parametrizacaoFiscal",
        component: () =>
          import(
            "@/components/comercial/parametrizacao-fiscal/ListagemParametrizacaoFiscal"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "cadastro.parametrizacaoFiscal",
        path: "dashboard/parametrizacaoFiscal/cadastro",
        component: () =>
          import(
            "@/components/comercial/parametrizacao-fiscal/CadastroParametrizacaoFiscal"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "editar.parametrizacaofiscal",
        path: "dashboard/parametrizacaoFiscal/:id/editar",
        component: () =>
          import(
            "@/components/comercial/parametrizacao-fiscal/EditarParametrizacaoFiscal"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },

      {
        name: "Contratos",
        path: "dashboard/contratos",
        component: () =>
          import("@/components/comercial/contratos/ListagemContratos"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "cadastro.contratos",
        path: "dashboard/contratos/cadastro",
        component: () =>
          import("@/components/comercial/contratos/CadastroContrato"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "editar.contrato",
        path: "dashboard/contratos/:id/editar",
        component: () =>
          import("@/components/comercial/contratos/EditarContrato"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },

      {
        name: "EscopoServicos",
        path: "dashboard/escopoServicos",
        component: () =>
          import(
            "@/components/comercial/escopo-servicos/ListagemEscopoServicos"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "cadastro.escopoServicos",
        path: "dashboard/escopoServicos/cadastro",
        component: () =>
          import(
            "@/components/comercial/escopo-servicos/CadastroEscopoServico"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "editar.escoposervico",
        path: "dashboard/escopoServicos/:id/editar",
        component: () =>
          import(
            "@/components/comercial/escopo-servicos/EditarEscopoServico"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },

      {
        name: "ParametrizacaoServicos",
        path: "dashboard/parametrizacaoServicos",
        component: () =>
          import(
            "@/components/comercial/parametrizacao-servicos/ListagemParametrizacaoServicos"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "cadastro.parametrizacaoServicos",
        path: "dashboard/parametrizacaoServicos/cadastro",
        component: () =>
          import(
            "@/components/comercial/parametrizacao-servicos/CadastroParametrizacaoServico"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "editar.parametrizacaoservico",
        path: "dashboard/parametrizacaoServicos/:id/editar",
        component: () =>
          import(
            "@/components/comercial/parametrizacao-servicos/EditarParametrizacaoServico"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },

      {
        name: "PostoTrabalhos",
        path: "dashboard/postoTrabalhos",
        component: () =>
          import(
            "@/components/comercial/posto-trabalho/ListagemPostoTrabalhos"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "cadastro.postoTrabalhos",
        path: "dashboard/postoTrabalhos/cadastro",
        component: () =>
          import(
            "@/components/comercial/posto-trabalho/CadastroPostoTrabalho"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "editar.postotrabalho",
        path: "dashboard/postoTrabalhos/:id/editar",
        component: () =>
          import("@/components/comercial/posto-trabalho/EditarPostoTrabalho"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },

      {
        name: "FuncionarioEscalaTrabalhos",
        path: "dashboard/funcionarioEscalaTrabalhos",
        component: () =>
          import(
            "@/components/pessoal/funcionario-escala-trabalho/ListagemFuncionarioEscalaTrabalhos"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "cadastro.funcionarioEscalaTrabalhos",
        path: "dashboard/funcionarioEscalaTrabalhos/cadastro",
        component: () =>
          import(
            "@/components/pessoal/funcionario-escala-trabalho/CadastroFuncionarioEscalaTrabalho"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "editar.funcionarioescalatrabalho",
        path: "dashboard/funcionarioEscalaTrabalhos/:id/editar",
        component: () =>
          import(
            "@/components/pessoal/funcionario-escala-trabalho/EditarFuncionarioEscalaTrabalho"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },

      {
        name: "FuncionarioMovimentacoes",
        path: "dashboard/funcionarioMovimentacoes",
        component: () =>
          import(
            "@/components/pessoal/funcionario-movimentacao/ListagemFuncionarioMovimentacoes"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "cadastro.FuncionarioMovimentacoes",
        path: "dashboard/funcionarioMovimentacoes/cadastro",
        component: () =>
          import(
            "@/components/pessoal/funcionario-movimentacao/CadastroFuncionarioMovimentacao"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "editar.funcionariomovimentacoes",
        path: "dashboard/funcionarioMovimentacoes/:id/editar",
        component: () =>
          import(
            "@/components/pessoal/funcionario-movimentacao/EditarFuncionarioMovimentacao"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },

      {
        name: "Cfops",
        path: "dashboard/cfops",
        component: () => import("@/components/comercial/cfop/ListagemCfops"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      /*         {
        name: "txt",
        path: "dashboard/txt",
        component: () => import("@/components/"),
        beforeEnter: ifAuthenticated,  
meta: {
          groupName: "Cajupe",
        },
      },
      {
        name: "editar.txT",
        path: "dashboard/txt/:id/editar",
        component: () => import("@/components//Editar"),
        beforeEnter: ifAuthenticated,  
meta: {
          groupName: "Cajupe",
        },
      },
      {
        name: "cadastro.txT",
        path: "dashboard/txt/cadastro",
        component: () => import("@/components//Editar"),
        beforeEnter: ifAuthenticated,  
meta: {
          groupName: "Cajupe",
        },
      }, */
      {
        name: "cadastro.Cfop",
        path: "dashboard/cfops/cadastro",
        component: () => import("@/components/comercial/cfop/CadastroCfop"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },

      {
        name: "editar.cfop",
        path: "dashboard/cfops/:id/editar",
        component: () => import("@/components/comercial/cfop/EditarCfop"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },

      {
        name: "cfopCidades",
        path: "dashboard/cfopCidades",
        component: () =>
          import("@/components/comercial/cfopCidades/ListagemCfopCidades"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "cadastro.CfopCidade",
        path: "dashboard/cfopCidades/cadastro",
        component: () =>
          import("@/components/comercial/cfopCidades/CadastroCfopCidade"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "editar.cfopcidade",
        path: "dashboard/cfopCidades/:id/editar",
        component: () =>
          import("@/components/comercial/cfopCidades/EditarCfopCidade"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },

      {
        name: "cfopEstados",
        path: "dashboard/cfopEstados",
        component: () =>
          import("@/components/comercial/cfopEstados/ListagemCfopEstados"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "cadastro.CfopEstado",
        path: "dashboard/cfopEstados/cadastro",
        component: () =>
          import("@/components/comercial/cfopEstados/CadastroCfopEstado"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },
      {
        name: "editar.cfopestado",
        path: "dashboard/cfopEstados/:id/editar",
        component: () =>
          import("@/components/comercial/cfopEstados/EditarCfopEstado"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Comercial",
        },
      },

      {
        name: "cadastro.EmailMarketing",
        path: "dashboard/enviarEmailMarketing",
        component: () =>
          import("@/components/email-marketing/EnviarEmailMarketing"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Marketing",
        },
      },
      {
        name: "EmailMarketing",
        path: "dashboard/EmailMarketing",
        component: () =>
          import("@/components/email-marketing/ListagemEmailMarketing"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Marketing",
        },
      },

      {
        name: "GraficosPeriodo",
        path: "dashboard/relatorios/funcionariopontos",
        component: () =>
          import("@/components/pessoal/funcionario-ponto/graficos/modelo01/Graficos"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },

      {
        name: "GraficosPeriodoMod02",
        path: "dashboard/relatorios/funcionariopontos/mod02",
        component: () =>
          import("@/components/pessoal/funcionario-ponto/graficos/modelo02/Graficos"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },


      {
        name: "GraficoRegistrosPonto",
        path: "dashboard/relatorios/funcionarioregistrosponto",
        component: () =>
          import("@/components/pessoal/funcionario-ponto/graficos/registro-ponto/DashboardModelo01"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Ponto-RRP",
        },
      },

      {
        name: "funcionarioAfastamentos",
        path: "dashboard/funcionarioAfastamentos",
        component: () =>
          import(
            "@/components/pessoal/funcionario-afastamento/ListagemFuncionarioAfastamentos"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "cadastro.FuncionarioAfastamentos",
        path: "dashboard/funcionarioAfastamentos/cadastro",
        component: () =>
          import(
            "@/components/pessoal/funcionario-afastamento/CadastroFuncionarioAfastamento"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "editar.funcionarioafastamento",
        path: "dashboard/funcionarioAfastamentos/:id/editar",
        component: () =>
          import(
            "@/components/pessoal/funcionario-afastamento/EditarFuncionarioAfastamento"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "FuncionarioBancoHorasContratos",
        path: "dashboard/FuncionarioBancoHorasContratos",
        component: () =>
          import("@/components/pessoal/banco-de-horas/ListagemBancoHoras"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "cadastro.FuncionarioBancoHorasContratos",
        path: "dashboard/FuncionarioBancoHorasContratos/cadastro",
        component: () =>
          import("@/components/pessoal/banco-de-horas/CadastroBancoHora"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "cadastro.FuncionarioBancoHorasContratosSaldo",
        path: "dashboard/FuncionarioBancoHorasContratos/cadastro/saldo",
        component: () =>
          import("@/components/pessoal/banco-de-horas/CadastroBancoHoraSaldo"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "editar.funcionariobancohorascontrato",
        path: "dashboard/FuncionarioBancoHorasContratos/:id/editar",
        component: () =>
          import(
            "@/components/pessoal/banco-de-horas/EditarBancoHora"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "gestaoEfetivo",
        path: "dashboard/gestaoEfetivo",
        component: () =>
          import("@/components/pessoal/gestao-efetivo/ListagemGestaoEfetivo"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },

      {
        name: "PedidoMateriais",
        path: "dashboard/pedidoMateriais",
        component: ListagemPedidoMaterial,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "Cadastro.pedidoMaterial",
        path: "dashboard/pedidoMateriais/cadastro",
        component: CadastroPedidoMaterial,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "editar.pedidomaterial",
        path: "dashboard/pedidoMaterial/:id/editar",
        component: EditarPedidoMaterial,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },

      {
        name: "PedidoUniformes",
        path: "dashboard/PedidoUniformes",
        component: ListagemPedidoUniforme,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "Cadastro.pedidoUniforme",
        path: "dashboard/pedidoUniformes/cadastro",
        component: CadastroPedidoUniforme,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "editar.pedidouniforme",
        path: "dashboard/pedidoUniforme/:id/editar",
        component: EditarPedidoUniforme,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },

      {
        name: "PedidoConsumiveis",
        path: "dashboard/PedidoConsumiveis",
        component: ListagemPedidoConsumivel,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "Cadastro.pedidoConsumivel",
        path: "dashboard/pedidoConsumiveis/cadastro",
        component: CadastroPedidoConsumivel,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "editar.pedidoconsumivel",
        path: "dashboard/pedidoConsumivel/:id/editar",
        component: EditarPedidoConsumivel,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },

      {
        name: "ContaFinanceiras",
        path: "dashboard/ContaFinanceiras",
        component: () =>
          import(
            "@/components/financeiro/conta-financeira/ListagemContaFinanceira"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "ContaFinanceirasConta",
        path: "dashboard/ContaFinanceirasConta",
        component: () =>
          import(
            "@/components/financeiro/conta-financeira/ListagemContaFinanceiraConta"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "cadastro.contaFinanceira",
        path: "dashboard/contaFinanceiras/cadastro",
        component: () =>
          import("@/components/financeiro/conta-financeira/CadastroContaFinanceira"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },
      {
        name: "editar.contafinanceira",
        path: "dashboard/contaFinanceira/:id/editar",
        component: () =>
          import("@/components/financeiro/conta-financeira/EditarContaFinanceira"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },

      {
        name: "Imobilizados",
        path: "dashboard/Imobilizados",
        component: () =>
          import(
            "@/components/recursos/imobilizados/ListagemImobilizado"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "cadastro.Imobilizado",
        path: "dashboard/Imobilizados/cadastro",
        component: () =>
          import("@/components/recursos/imobilizados/CadastroImobilizado"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "editar.imobilizado",
        path: "dashboard/Imobilizado/:id/editar",
        component: () =>
          import("@/components/recursos/imobilizados/EditarImobilizado"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },

      {
        name: "ImobilizadoMovimentacoes",
        path: "dashboard/ImobilizadoMovimentacoes",
        component: () =>
          import(
            "@/components/recursos/imobilizado-movimentacoes/ListagemImobilizadoMovimentacao"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "cadastro.ImobilizadoMovimentacao",
        path: "dashboard/ImobilizadoMovimentacoes/cadastro",
        component: () =>
          import("@/components/recursos/imobilizado-movimentacoes/CadastroImobilizadoMovimentacao"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "editar.imobilizadomovimentacao",

        path: "dashboard/ImobilizadoMovimentacao/:id/editar",
        component: () =>
          import("@/components/recursos/imobilizado-movimentacoes/EditarImobilizadoMovimentacao"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },

      {
        name: "FuncionarioFolhaPagamentoPeriodos",
        path: "dashboard/FuncionarioFolhaPagamentoPeriodos",
        component: () =>
          import(
            "@/components/pessoal/funcionario-folha-pagamento-periodo/ListagemFuncionarioFolhaPagamentoPeriodo"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "cadastro.FuncionarioFolhaPagamentoPeriodo",
        path: "dashboard/FuncionarioFolhaPagamentoPeriodos/cadastro",
        component: () =>
          import(
            "@/components/pessoal/funcionario-folha-pagamento-periodo/CadastroFuncionarioFolhaPagamentoPeriodo"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "editar.funcionariofolhapagamentoperiodo",

        path: "dashboard/FuncionarioFolhaPagamentoPeriodo/:id/editar",
        component: () =>
          import(
            "@/components/pessoal/funcionario-folha-pagamento-periodo/EditarFuncionarioFolhaPagamentoPeriodo"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },

      {
        name: "CalculoFaturamento",
        path: "dashboard/calculoFaturamentos",
        component: () =>
          import(
            "@/components/faturamento/calculo-faturamento/ListagemCalculoFaturamento"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Faturamento",
        },
      },

      {
        name: "PracaFiscais",
        path: "dashboard/PracaFiscais",
        component: () =>
          import(
            "@/components/faturamento/praca-fiscal/ListagemPracaFiscal"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Faturamento",
        },
      },
      {
        name: "cadastro.PracaFiscal",
        path: "dashboard/PracaFiscais/cadastro",
        component: () =>
          import("@/components/faturamento/praca-fiscal/CadastroPracaFiscal"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Faturamento",
        },
      },
      {
        name: "editar.faturamentopracafiscal",
        path: "dashboard/PracaFiscal/:id/editar",
        component: () =>
          import("@/components/faturamento/praca-fiscal/EditarPracaFiscal"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Faturamento",
        },
      },

      {
        name: "DocumentoFiscal",
        path: "dashboard/documentoFiscais",
        component: () =>
          import(
            "@/components/faturamento/documento-fiscal/ListagemDocumentoFiscal"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Faturamento",
        },
      },
      {
        name: "cadastro.faturamentodocumentofiscal",
        path: "dashboard/DocumentoFiscal/cadastro",
        component: () =>
          import("@/components/faturamento/documento-fiscal/CadastroDocumentoFiscal"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Faturamento",
        },
      },
      {
        name: "editar.faturamentodocumentofiscal",
        path: "dashboard/DocumentoFiscal/:id/editar",
        component: () =>
          import("@/components/faturamento/documento-fiscal/EditarDocumentoFiscal"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Faturamento",
        },
      },

      {
        name: "BeneficioConvenios",
        path: "dashboard/BeneficioConvenios",
        component: () =>
          import(
            "@/components/pessoal/beneficio/convenio/ListagemBeneficioConvenio"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Beneficio-BEN",
        },
      },
      {
        name: "cadastro.BeneficioConvenio",
        path: "dashboard/BeneficioConvenios/cadastro",
        component: () =>
          import("@/components/pessoal/beneficio/convenio/CadastroBeneficioConvenio"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Beneficio-BEN",
        },
      },
      {
        name: "editar.beneficioconvenio",
        path: "dashboard/BeneficioConvenio/:id/editar",
        component: () =>
          import("@/components/pessoal/beneficio/convenio/EditarBeneficioConvenio"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Beneficio-BEN",
        },
      },


      {
        name: "BeneficioConvenioOptantes",
        path: "dashboard/BeneficioConvenioOptantes",
        component: () =>
          import(
            "@/components/pessoal/beneficio/optante/ListagemBeneficioConvenioOptante"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Beneficio-BEN",
        },
      },
      {
        name: "cadastro.BeneficioConvenioOptante",
        path: "dashboard/BeneficioConvenioOptantes/cadastro",
        component: () =>
          import("@/components/pessoal/beneficio/optante/CadastroBeneficioConvenioOptante"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Beneficio-BEN",
        },
      },
      {
        name: "editar.beneficioconveniooptante",
        path: "dashboard/BeneficioConvenioOptante/:id/editar",
        component: () =>
          import("@/components/pessoal/beneficio/optante/EditarBeneficioConvenioOptante"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Beneficio-BEN",
        },
      },

      {
        name: "BeneficioPeriodoApuracoes",
        path: "dashboard/BeneficioPeriodoApuracoes",
        component: () =>
          import(
            "@/components/pessoal/beneficio/periodo/ListagemBeneficioPeriodoApuracao"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Beneficio-BEN",
        },
      },
      {
        name: "cadastro.BeneficioPeriodoApuracao",
        path: "dashboard/BeneficioPeriodoApuracoes/cadastro",
        component: () =>
          import("@/components/pessoal/beneficio/periodo/CadastroBeneficioPeriodoApuracao"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Beneficio-BEN",
        },
      },
      {
        name: "editar.beneficioperiodoapuracao",
        path: "dashboard/BeneficioPeriodoApuracao/:id/editar",
        component: () =>
          import("@/components/pessoal/beneficio/periodo/EditarBeneficioPeriodoApuracao"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Beneficio-BEN",
        },
      },

      {
        name: "BeneficioApuracao",
        path: "dashboard/BeneficioApuracoes",
        component: () =>
          import(
            "@/components/pessoal/beneficio/calculo/ListagemBeneficioApuracao"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Beneficio-BEN",
        },
      },

      {
        name: "usuarioPerfis",
        path: "dashboard/usuarioPerfis",
        component: () =>
          import("@/components/acessos/perfis/ListagemPerfil"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Software",
        },
      },
      {
        name: "cadastro.Perfil",
        path: "dashboard/usuarioPerfis/cadastro",
        component: () =>
          import("@/components/acessos/perfis/CadastroPerfil"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Software",
        },
      },
      {
        name: "editar.usuarioperfil",
        path: "dashboard/usuarioPerfis/:id/editar",
        component: () =>
          import("@/components/acessos/perfis/EditarPerfil"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Software",
        },
      },

      {
        name: "permissoes",
        path: "dashboard/permissoes",
        component: () =>
          import("@/components/acessos/permissoes/ListagemPermissao"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Software",
        },
      },
      {
        name: "cadastro.Permissao",
        path: "dashboard/permissoes/cadastro",
        component: () =>
          import("@/components/acessos/permissoes/CadastroPermissao"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Software",
        },
      },
      {
        name: "editar.permissao",
        path: "dashboard/permissoes/:id/editar",
        component: () =>
          import("@/components/acessos/permissoes/EditarPermissao"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Software",
        },
      },

      {
        name: "beneficio.analise.cliente",
        path: "dashboard/BeneficioAnalise/cliente",
        component: () =>
          import(
            "@/components/pessoal/beneficio/analise-cliente/Realizado"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Beneficio-BEN",
        },
      },
      {
        name: "MetodoCobrancas",
        path: "dashboard/metodocobrancas",
        component: () => import("@/components/comercial/metodo-cobranca/ListagemMetodoCobranca"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Faturamento",
        },
      },
      {
        name: "cadastro.MetodoCobranca",
        path: "dashboard/metodocobrancas/cadastro",
        component: () => import("@/components/comercial/metodo-cobranca/CadastroMetodoCobranca"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Faturamento",
        },
      },

      {
        name: "editar.metodocobranca",
        path: "dashboard/metodocobrancas/:id/editar",
        component: () => import("@/components/comercial/metodo-cobranca/EditarMetodoCobranca"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Faturamento",
        },
      },

      {
        name: "CompraPedidos",
        path: "dashboard/comprapedidos",
        component: () => import("@/components/recursos/compras/compra-pedido/ListagemCompraPedido"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Compras",
        },
      },
      {
        name: "cadastro.Comprapedidos",
        path: "dashboard/comprapedidos/cadastro",
        component: () => import("@/components/recursos/compras/compra-pedido/CadastroCompraPedido"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Compras",
        },
      },

      {
        name: "editar.comprapedidos",
        path: "dashboard/comprapedidos/:id/editar",
        component: () => import("@/components/recursos/compras/compra-pedido/EditarCompraPedido"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Compras",
        },
      },
      {
        name: "CompraOrcamentos",
        path: "dashboard/compraorcamentos",
        component: () => import("@/components/recursos/compras/compra-orcamento/ListagemCompraOrcamento"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Compras",
        },
      },
      {
        name: "cadastro.Compraorcamentos",
        path: "dashboard/compraorcamentos/cadastro",
        component: () => import("@/components/recursos/compras/compra-orcamento/CadastroCompraOrcamento"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Compras",
        },
      },

      {
        name: "editar.compraorcamentos",
        path: "dashboard/compraorcamentos/:id/editar",
        component: () => import("@/components/recursos/compras/compra-orcamento/EditarCompraOrcamento"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Compras",
        },
      },

      {
        name: "CompraOrdens",
        path: "dashboard/compraordens",
        component: () => import("@/components/recursos/compras/compra-ordem/ListagemCompraOrdem"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Compras",
        },
      },


      {
        name: "cadastro.Feriados",
        path: "dashboard/feriados/cadastro",
        component: () => import("@/components/software/feriados/CadastroFeriado"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Software",
        },
      },

      {
        name: "editar.feriado",
        path: "dashboard/feriados/:id/editar",
        component: () => import("@/components/software/feriados/EditarFeriado"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Software",
        },
      },

      {
        name: "Feriados",
        path: "dashboard/feriados",
        component: () => import("@/components/software/feriados/ListagemFeriados"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Software",
        },
      },

      {
        name: "faturamentovariaveisservicos",
        path: "dashboard/faturamentoVariaveisServicos",
        component: () =>
          import(
            "@/components/faturamento/variaveis-servico/ListagemVariaveisServico"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Faturamento",
        },
      },
      {
        name: "cadastro.FaturamentoVariaveisServicos",
        path: "dashboard/faturamentoVariaveisServicos/cadastro",
        component: () =>
          import(
            "@/components/faturamento/variaveis-servico/CadastroVariaveisServico"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Faturamento",
        },
      },
      {
        name: "editar.faturamentovariaveisservico",
        path: "dashboard/faturamentoVariaveisServicos/:id/editar",
        component: () =>
          import(
            "@/components/faturamento/variaveis-servico/EditarVariaveisServico"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Faturamento",
        },
      },

      {
        name: "registrotarefaperiodo",
        path: "dashboard/registrotarefaperiodos",
        component: () =>
          import(
            "@/components/operacoes/tarefas/periodo/ListagemTarefasPeriodos"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "GestaoTarefasDiarias",
        },
      },
      {
        name: "cadastro.registrotarefaperiodo",
        path: "dashboard/registrotarefaperiodos/cadastro",
        component: () =>
          import(
            "@/components/operacoes/tarefas/periodo/CadastroTarefasPeriodo"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "GestaoTarefasDiarias",
        },
      },
      {
        name: "editar.registrotarefaperiodo",
        path: "dashboard/registrotarefaperiodos/:id/editar",
        component: () =>
          import(
            "@/components/operacoes/tarefas/periodo/EditarTarefasPeriodo"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "GestaoTarefasDiarias",
        },
      },
      {
        name: "RegistroTarefaCalculo",
        path: "dashboard/registrotarefacalculo",
        component: () =>
          import(
            "@/components/operacoes/tarefas/calculo/ListagemRegistrosTarefaApuracao"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "GestaoTarefasDiarias",
        },
      },
      {
        name: "lancamentosContabeis",
        path: "dashboard/lancamentocontabil",
        component: () =>
          import(
            "@/components/contabilidade/LancamentoContabil"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Contabildade",
        },
      },

      {
        name: "AvisoPrevio",
        path: "dashboard/funcionarioavisoprevios",
        component: ListagemAvisoPrevio,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "cadastro.funcionarioAvisoPrevio",
        path: "dashboard/funcionarioavisoprevios/cadastro",
        component: CadastroAvisoPrevio,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "editar.funcionarioavisoprevio",
        path: "dashboard/funcionarioavisoprevio/:id/editar",
        component: EditarAvisoPrevio,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },

      {
        name: "ModeloDocumentacaoAcessoria",
        path: "dashboard/modeloDocumentacaoAcessorias",
        component: ListagemModeloDocumentacaoAcessoria,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "cadastro.modeloDocumentacaoAcessoria",
        path: "dashboard/modeloDocumentacaoAcessorias/cadastro",
        component: CadastroModeloDocumentacaoAcessoria,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "editar.modelodocumentacaoacessoria",
        path: "dashboard/modeloDocumentacaoAcessoria/:id/editar",
        component: EditarModeloDocumentacaoAcessoria,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "OrcamentoDespesa",
        path: "dashboard/orcamentoDespesa",
        component: ListageOrcamentoDespesa,
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Financeiro",
        },
      },


      {
        name: "funcionarioAfastamentoSolicitacao",
        path: "dashboard/funcionarioAfastamentoSolicitacoes",
        component: () =>
          import(
            "@/components/pessoal/funcionario-afastamento-solicitacao/ListagemFuncionarioAfastamentoSolicitacao"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "cadastro.FuncionarioAfastamentoSolicitacao",
        path: "dashboard/funcionarioAfastamentoSolicitacao/cadastro",
        component: () =>
          import(
            "@/components/pessoal/funcionario-afastamento-solicitacao/CadastroFuncionarioAfastamentoSolicitacao"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },
      {
        name: "importarHorarios",
        path: "dashboard/gestaoAcademicaHorarios",
        component: () =>
          import(
            "@/components/pessoal/funcionario-escala-trabalho/GestaoAcademicaHorarios"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "GAH",
        },
      },

      {
        name: "ContaVinculadaMovimentacoes",
        path: "dashboard/contaVinculadaMovimentacoes",
        component: () =>
          import(
            "@/components/financeiro/conta-vinculada-movimentacao/ListagemContaVinculadaMovimentacao"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "ContaVinculada",
        },
      },

      {
        name: "cadastro.ContaVinculadaMovimentacao",
        path: "dashboard/contaVinculadaMovimentacoes/cadastro",
        component: () =>
          import("@/components/financeiro/conta-vinculada-movimentacao/CadastroContaVinculadaMovimentacao"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "ContaVinculada",
        },
      },
      {
        name: "editar.contavinculadamovimentacao",
        path: "dashboard/contaVinculadaMovimentacoes/:id/editar",
        component: () =>
          import("@/components/financeiro/conta-vinculada-movimentacao/EditarContaVinculadaMovimentacao"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "ContaVinculada",
        },
      },

      {
        name: "Equipamentos",
        path: "dashboard/equipamentos",
        component: () =>
          import(
            "@/components/operacoes/equipamentos/ListagemEquipamento"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },

      {
        name: "EquipamentoAnaliseMovimentacao",
        path: "dashboard/analiseMovimentacaoEquipamentos",
        component: () =>
          import(
            "@/components/operacoes/equipamentos/AnaliseMovimentacao"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },

      {
        name: "CodigoIdentificacaoReciprocas",
        path: "dashboard/codigoIdentificacaoReciprocas",
        component: () =>
          import(
            "@/components/operacoes/codigoIdentificacaoReciproca/ListagemCodigoIdentificacaoReciproca"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },

      {
        name: "EquipamentoAnaliseCredito",
        path: "dashboard/analiseSaldoLogicoEquipamentos",
        component: () =>
          import(
            "@/components/operacoes/equipamentos/AnaliseSaldoLogico"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },

      {
        name: "EquipamentoEncerramentoApuracaoSaldoData",
        path: "dashboard/equipamentoEncerramentoApuracaoSaldoDatas",
        component: () =>
          import(
            "@/components/operacoes/equipamentoEncerramentoApuracaoSaldoData/ListagemEquipamentoEncerramentoApuracaoSaldoData"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },


      {
        name: "EquipamentosDashboard01",
        path: "dashboard/equipamentos/dashboard01",
        component: () =>
          import(
            "@/components/operacoes/equipamentos/DashboardModelo01"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },

      {
        name: "LogisticaTipoEvento",
        path: "dashboard/logisticaTipoEvento",
        component: () =>
          import(
            "@/components/operacoes/logisticaTipoEvento/ListagemLogisticaTipoEvento"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },

      {
        name: "LogisticaTipoOcorrencia",
        path: "dashboard/logisticaTipoOcorrencia",
        component: () =>
          import(
            "@/components/operacoes/logisticaTipoOcorrencia/ListagemLogisticaTipoOcorrencia"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },
      {
        name: "CentroEstoque",
        path: "dashboard/centroEstoques",
        component: () =>
          import("@/components/recursos/centro-estoque/ListagemCentroEstoque"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "ProdutosCentroEstoque",
        path: "dashboard/produtosCentroEstoque",
        component: () =>
          import("@/components/recursos/produtos/ListagemProdutosCentroEstoque"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "PedidoConsumivelCentroEstoque",
        path: "dashboard/pedidosConsumivelCentroEstoque",
        component: () =>
          import("@/components/recursos/pedido-consumivel-centro-estoque/ListagemPedidos"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Recursos",
        },
      },
      {
        name: "FuncionarioDocumentacaoAcessoria",
        path: "dashboard/funcionarioDocumentacaoAcessoria",
        component: () =>
          import("@/components/pessoal/funcionario-documentacao-acessoria/ListagemFuncionarioDocumentacaoAcessoria"),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Pessoal",
        },
      },

      {
        name: "AssistenciaTecnicaTipo",
        path: "dashboard/assistenciaTecnicaTipo",
        component: () =>
          import(
            "@/components/operacoes/assistenciaTecnicaTipo/ListagemAssistenciaTecnicaTipo"
          ),
        beforeEnter: ifAuthenticated,
        meta: {
          groupName: "Operacoes",
        },
      },



      // {
      //   name: "editar.funcionarioafastamento",
      //   path: "dashboard/funcionarioAfastamentos/:id/editar",
      //   component: () =>
      //     import(
      //       "@/components/pessoal/funcionario-afastamento/EditarFuncionarioAfastamento"
      //     ),
      //   beforeEnter: ifAuthenticated,
      //   meta: {
      //     groupName: "Pessoal",
      //   },
      // },

    ]
  },

];

const router = new VueRouter({
  routes,
});

export default router;
